import DateFnsUtils from "@date-io/date-fns";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  IconButton,
  Chip,
} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PhotoLibraryRoundedIcon from "@material-ui/icons/PhotoLibraryRounded";
import PublishIcon from "@material-ui/icons/Publish";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ChipsArray from "../ChipsArray/ChipsArray";
import "./VendorExperienceUpdate.scss";
import LocalStorage from "../../_services/LocalStorage";
import Autocomplete from "@mui/material/Autocomplete";
import NoAccess from "../NoAccess/NoAccess";
import { dropDownData } from "../../constants/updateVendorConstants";
import FileStorage from "../FileStorage";
import * as _ from "lodash";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";
import { BLOCK_TAG_NAME, EMPTY_TEXT_VALUES, modules } from "components/CustomReactQuillEditor/reactQuill.constants";
import ReactQuill from "react-quill";
import { cleanFormatting } from "components/CustomReactQuillEditor/reactQuill.helper";
import { LinkAvailableGalleryItemsModal } from "components/LinkAvailableGalleryItemsModal/LinkAvailableGalleryItemsModal";
import { getUrlFromMediaObject } from "components/VendorCapabilitiesUpdate/components/GalleryModal/galleryModal.handler";
import { Typography } from "antd";
import { usePrompt } from "components/Modal/usePrompt";
import { Flex } from "venwiz-ui";
import { checkUserHasOnlyVendorViewRole } from "shared/helpers/checkHasAccess";
import SnackBarNotification from "components/SnackBar/SnackBar";
import { ImageAttactedCount, useDeepCompareMemoize } from "./helpers/vendorExperienceUpdate.helper";

const useStyles = makeStyles((theme) => ({
  peopleInfoContainer: {
    padding: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  subHeader: {
    color: "#66759c",
    fontWeight: 600,
    float: "left",
  },
  editAddFields: {
    marginTop: "15px",
    color: "#8fc9f9",
    fontWeight: 400,
    fontSize: "13px",
  },
  editButton: {
    backgroundColor: "#06c396",
    color: "#fff",
  },
  saveButton: {
    backgroundColor: "#1f60c9",
    color: "#fff",
  },
  projectDescriptionSectionTitie: {
    color: "#66759c",
    fontWeight: 600,
    float: "left",
    marginTop: "10px",
  },
  reactQuillSuperContainer: {
    paddingTop: "32px",
  },
}));

const VendorExperienceUpdate = () => {
  const classes = useStyles();
  const [loader, enableLoader] = React.useState(false);
  const [reloadUpdate, setReloadUpdate] = React.useState(false);
  const [experiences, setExperiencesData] = React.useState({});
  const [editChips, setEditChips] = React.useState(false);
  const [editChipsData, setEditChipsData] = React.useState({
    /** @type {import('../VendorCapabilitiesUpdate/components/GalleryModal/GalleryModal.interface').IMediaItems} */
    gallery: [],
  });
  const [currExpDescription, setCurrExpDescription] = React.useState("");
  const [updateId, setUpdateId] = React.useState("");
  const [openWarningModal, setWarningModal] = React.useState(false);
  const [modalShowData, setModalShowData] = React.useState({});
  const [newDataMode, setNewDataMode] = React.useState(false);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setendDate] = React.useState("");
  const [gstnValue, setGstnValue] = React.useState("");
  const [prjDocument, setPrjDocument] = React.useState({});
  const { gstn } = useParams();
  const [showLinkGalleryModal, setShowLinkGalleryModal] = React.useState(false);
  const [isDescriptionEmpty, setIsDescriptionEmpty] = React.useState(false);
  const [isCityEmpty, setCityEmpty] = React.useState(false);
  const [isClientCompanyNameEmpty, setClientCompanyNameEmpty] = React.useState(false);
  /** filtered media items with category as "Projects" */
  const [filteredMediaItemsWithCategoryAsProjects, setFilteredMediaItemsWithCategoryAsProjects] = React.useState([]);
  const prompt = usePrompt();

  const access = checkUserHasOnlyVendorViewRole()

  const [hasAccess, setHasAccess] = React.useState(access);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [currentChipIndex, setCurrentChipIndex] = useState(-1);
  const [uploadedImages, setUploadedImages] = useState([]);

  const Quill = ReactQuill.Quill;
  var Block = Quill.import("blots/block");
  Block.tagName = BLOCK_TAG_NAME;
  Quill.register(Block);

  const removeExperiences = (chip) => {
    setWarningModal(true);
    setModalShowData(chip);
    setEditChipsData(chip);
  };
  const editExperienceData = (chip, index) => {
    setEditChips(true);
    setEditChipsData(chip);
    setNewDataMode(false);
    setCurrentChipIndex(index);
  };

  const saveChipsData = (chip, updateId, newMode, gstn) => {
    if (chip.clientCompanyName || chip.companyProfile) {
      const DESCRIPTION = currExpDescription || "";
      if (newMode) {
        let payload = {
          clientCompanyName: chip.clientCompanyName || chip.companyProfile || "",
          companyProfile: chip.companyProfile ? chip.companyProfile : "",
          description: DESCRIPTION,
          projectName: chip.projectName ? chip.projectName : "",
          clientIndustry: chip.clientIndustry ? chip.clientIndustry : "",
          projectCost: chip.projectCost ? chip.projectCost : "",
          city: chip.city ? chip.city : "",
          projStartDate: chip.projStartDate ? chip.projStartDate : "MM-YYYY",
          projEndDate: chip.projEndDate ? chip.projEndDate : "MM-YYYY",
          document: chip.document,
          gallery: chip.gallery || [],
        };
        let payloadList = [];
        payloadList.push(payload);
        createExperience(payloadList, gstn);
      } else {
        setExperiencesData((itr) => itr.map((it) => (it._id === chip._id ? chip : it)));
        chip.description = DESCRIPTION;
        upDateVendorExperienceData(chip, updateId, gstn);
      }
    }
  };

  const createExperience = async (payloadList, gstn) => {
    let requestData = {
      gstn: gstn,
      expriences: payloadList,
    };
    const result = await axios.post(process.env.REACT_APP_BASE_URL + "/experiences", requestData, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    });
    leadSquaredTrigger(gstn);
    setReloadUpdate(true);
    setEditChips(false);
  };

  const upDateVendorExperienceData = async (experience, updateId, gstn) => {
    const result = await axios.put(process.env.REACT_APP_BASE_URL + "/experiences/" + updateId, experience, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    }); 
    leadSquaredTrigger(gstn);
    setReloadUpdate(true);
    setEditChips(false);
  };

  const upDateVendorExperienceDataBulk = async (experiences, updateId, gstn, removedChip) => {
    setExperiencesData((chips) => chips.filter((itr) => itr.value !== removedChip.value));
    let extractedSet = experiences.filter((itr) => itr.value !== removedChip.value);
    setWarningModal(false);
    let payload = {
      gstn: gstn,
      experiences: extractedSet,
    };
    const result = await axios.put(process.env.REACT_APP_BASE_URL + "/experiences/exps/" + updateId, payload, {
      headers: {
        authorization: `Bearer ${LocalStorage.get("token")}`,
      },
    });
    leadSquaredTrigger(gstn);
    setReloadUpdate(true);
    setEditChips(false);
  };

  const setStartExperienceDates = (date, originalChips) => {
    setStartDate(moment(date).format("YYYY-MM"));
    setEditChipsData({ ...originalChips, projStartDate: moment(date).format("YYYY-MM") });
  };

  const setEndExperienceDates = (date, originalChips) => {
    setendDate(moment(date).format("YYYY-MM"));
    setEditChipsData({ ...originalChips, projEndDate: moment(date).format("YYYY-MM") });
  };

  const setFieldName = (e, originalChips) => {
    if (e.target.id === "clientCompanyName"){
      setEditChipsData({ ...originalChips, clientCompanyName: e.target.value, label: e.target.value });
      if (e.target.value.trim().length <= 0) {
        setClientCompanyNameEmpty(true);
      } else {
        setClientCompanyNameEmpty(false);
      }
    }
    if (e.target.id === "clientIndustry"){
      setEditChipsData({ ...originalChips, clientIndustry: e.target.value });
    } 
    if (e.target.id === "projectCost") setEditChipsData({ ...originalChips, projectCost: e.target.value });
    if (e.target.id === "city"){
      setEditChipsData({ ...originalChips, city: e.target.value });
      if (e.target.value.trim().length <= 0) {
        setCityEmpty(true);
      } else {
        setCityEmpty(false);
      }
    }
    if (e.target.id === "companyProfile") setEditChipsData({ ...originalChips, companyProfile: e.target.value });
    // commented for future reference
    // if (e.target.id === "description") setEditChipsData({ ...originalChips, description: e.target.value });
    if (e.target.id === "projectName") setEditChipsData({ ...originalChips, projectName: e.target.value });
    if (e.target.id === "projStartDate") {
      setEditChipsData({ ...originalChips, projStartDate: e.target.value });
    }
    if (e.target.id === "projEndDate") setEditChipsData({ ...originalChips, projEndDate: e.target.value });
  };

  const handleClose = () => {
    setWarningModal(false);
  };

  const addExperience = () => {
    setEditChips(true);
    setEditChipsData({});
    setCurrExpDescription("");
    setNewDataMode(true);
  };

  /** Feed latest-experience-description value 
   * whenever editChipsData.description changes*/
  useEffect(() => {
    setCurrExpDescription(editChipsData?.description || "");
  }, [editChipsData?.description])

  /** Fetch Capabilities Details only once when this page loads */
  useEffect(async () => {
    await axios.get(
      process.env.REACT_APP_BASE_URL +
      "/capabilities/new-capabilities/" +
      gstn,
      {
        headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
      }
    ).then((result) => {
      if (result.data?.status == "SUCCESS") {
        const capabilitiesResp = result?.data?.data || {};
        const galleryImages = capabilitiesResp?.gallery
          ? capabilitiesResp?.gallery?.filter(mediaObj => mediaObj?.category === "Projects")
          : [];
        const companyVideos = capabilitiesResp?.companyVideo
          ? capabilitiesResp?.companyVideo?.filter(mediaObj => mediaObj?.category === "Projects")
          : [];
        setFilteredMediaItemsWithCategoryAsProjects([...galleryImages, ...companyVideos]);
      }
    }).catch((error) => {});
  }, [reloadUpdate]);

  useEffect(async () => {
    enableLoader(true);
    setGstnValue(gstn);
    await axios
      .get(process.env.REACT_APP_BASE_URL + "/experiences/" + gstn, {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      })
      .then((result) => {
        if (result.data.status == "SUCCESS") {
          setUpdateId(result.data.data?._id);
          let resultSet = result.data.data?.experience?.length
            ? result.data.data?.experience.map((itr) => {
                return { 
                  ...itr, 
                  label: itr?.clientCompanyName || itr?.companyProfile || "", 
                  value: itr._id 
                };
              })
            : [];
          setExperiencesData(resultSet);
          setReloadUpdate(false);
        }
        enableLoader(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setHasAccess(false);
        }
      });
  }, [reloadUpdate]);

  // commented for future reference
  // const uploadFile = async (e) => {
  //   const file = e.target.files[0];
  //   if (file.size / 1024 / 1024 > 20) {
  //     console.error(`File size exceeds the 20 mb limit. Please try uploading a smaller file size`);
  //   }
  //   if (typeof file !== undefined) {
  //     const data = new FormData();
  //     data.append("file", file);
  //     const result = await axios.post(process.env.REACT_APP_BASE_URL + "/file-storage/upload", data, {
  //       headers: {
  //         authorization: `Bearer ${LocalStorage.get("token")}`,
  //       },
  //     });
  //     if (result.data.status === "SUCCESS") {
  //       setPrjDocument({ name: file.name, documentFile: result.data.data });
  //     }
  //   }
  // };
  // const createPermanentLinkAndAdd = async (payload, fileName) => {
  //   const result = await axios.post(process.env.REACT_APP_BASE_URL + "/file-storage/permanent", payload, {
  //     headers: {
  //       authorization: `Bearer ${LocalStorage.get("token")}`,
  //     },
  //   });
  //   if (result.data.status === "SUCCESS") {
  //     const documentFile = result.data.data;
  //     setEditChipsData((currData) => {
  //       currData.document = { name: fileName, documentFile };
  //       return currData;
  //     });
  //     setPrjDocument({});
  //   }
  // };
  // const openPrjDocument = (value) => {
  //   const win = window.open(value, "_blank");
  //   win.focus();
  // };

  const handleFileChange = (file, fileName) => {
    setEditChipsData((currData) => {
      currData.document = { name: fileName, documentFile: file };
      return currData;
    });
    setPrjDocument({ name: fileName, documentFile: file });
  }

  const removeProjectDocument = (e, element) => {
    setEditChipsData((currData) => ({ ...currData, document: {} }));
  };

  const formattedDocument = (document) => {
    return {
      name: document.name,
      file: document.documentFile
    }
  }

  const handleCloseLinkGalleryModal = () => setShowLinkGalleryModal(false);

  /** Variables Related to linking media item to project */
  const ID_OF_CURR_PROJECT = editChipsData?._id || "";
  const associatedMediaItems = useMemo(() => {
    /** filter out media items that are associated with other projects,
     *  because one media item can only be associa  ted with one project */
    let allMediaItems = filteredMediaItemsWithCategoryAsProjects;
    /** @type {string[]} */
    let MEDIA_URLS_ALREADY_ASSOCIATED_WITH_OTHER_PROJECTS = [];
    for (let j = 0; j < experiences?.length; j++) {
      const existingProject = experiences[j];
      if (
        existingProject?._id && ID_OF_CURR_PROJECT && existingProject?._id === ID_OF_CURR_PROJECT
      ) {
        /** Skip the current project */
        continue;
      } else {
        /** Add all media items associated with other projects */
        if (existingProject?.gallery) {
          existingProject?.gallery?.forEach((mediaObj) => {
            const MEDIA_URL = getUrlFromMediaObject(mediaObj);
            if (MEDIA_URL) MEDIA_URLS_ALREADY_ASSOCIATED_WITH_OTHER_PROJECTS.push(MEDIA_URL);
          });
        }
      }
    };

    allMediaItems = [...allMediaItems, ...uploadedImages];
    const availableMediaItems = allMediaItems.filter((mediaObj) => {
      const MEDIA_URL = getUrlFromMediaObject(mediaObj);
      const IS_CURR_MEDIA_URL_NOT_ASSOCIATED_WITH_ANY_PROJECT = !MEDIA_URLS_ALREADY_ASSOCIATED_WITH_OTHER_PROJECTS.includes(MEDIA_URL)
      return IS_CURR_MEDIA_URL_NOT_ASSOCIATED_WITH_ANY_PROJECT;
    });

    return availableMediaItems || [];
    
  }, [
    reloadUpdate,
    editChipsData?.gallery?.length,
    useDeepCompareMemoize(experiences).length,
    useDeepCompareMemoize(editChipsData)?._id,
    useDeepCompareMemoize(filteredMediaItemsWithCategoryAsProjects)?.length,
  ]);

  const handleRemoveMediaItemFromCurrProjectExp = (
    /** @type {import('../VendorCapabilitiesUpdate/components/GalleryModal/GalleryModal.interface').IMediaItem} */
    mediaItemObj
  ) => {
    const MEDIA_URL = getUrlFromMediaObject(mediaItemObj);
    const newGallery = editChipsData?.gallery?.filter((galleryItem) => {
      const galleryIteemURL = getUrlFromMediaObject(galleryItem);
      return galleryIteemURL !== MEDIA_URL;
    }) || [];
    setEditChipsData({ ...editChipsData, gallery: newGallery });
  };

  const handleAddMediaItemToCurrProjectExp = (
    /** @type {import('../VendorCapabilitiesUpdate/components/GalleryModal/GalleryModal.interface').IMediaItem} */
    mediaItemObj
  ) => {
    const newGallery = [...(editChipsData?.gallery || []), mediaItemObj] || [];
    setEditChipsData({ ...editChipsData, gallery: newGallery });
  };

  const handleUploadMediaItemToCurrProjectExp = (
    /** @type {import('../VendorCapabilitiesUpdate/components/GalleryModal/GalleryModal.interface').IMediaItem[]} */
    mediaItemObj
  ) => {
    const newGallery = [...(editChipsData?.gallery || []), ...mediaItemObj] || [];
    setUploadedImages([...uploadedImages, ...mediaItemObj]);
    setEditChipsData({ ...editChipsData, gallery: newGallery });
  };

  const setExperiencesDataReorder = async (newExperienceList) => {
    setExperiencesData(newExperienceList);
    try {
      let requestData = {
        gstn: gstnValue,
        expriences: newExperienceList,
      };
      const result = await axios.post(process.env.REACT_APP_BASE_URL + "/experiences/reorder", requestData, {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      });
      console.log(result);
    } catch (err) {
      console.log(err);
    }
  }

  const handleUpload = async (
    file,
    fileName
  ) => {
    const data = new FormData();
    data.append("file", file);
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/upload",
      data,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result.data.status === "SUCCESS") {
      const permanentFileUploadResult = await axios.post(
        process.env.REACT_APP_BASE_URL + "/file-storage/permanent",
        result.data.data,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      );
      if (permanentFileUploadResult?.data?.status === "SUCCESS") {
        const file = permanentFileUploadResult.data.data;
        const finalFile = {
          file: file,
          imageName: fileName,
          category: "Projects",
          caption: fileName.split('.')[0],
        }
        return finalFile;
      } else {
        setShowNotification(true);
        setNotificationMessage("Error in getting permanent files")
      }
    } else {
      setShowNotification(true);
      setNotificationMessage("Error in uploading files")
    }
  };

  const handleImageUpload = async (e, clientCompanyName) => {
    enableLoader(true);
    prompt.setIsDataChanged(true);
    const tempFile = e?.target?.files;
    const filesArray = [];
    for (let i = 0; i < tempFile?.length; i++) {
      const file = tempFile[i];
      const fileName = file?.name;
      const filelink = await handleUpload(file, fileName);
      filesArray.push(filelink);
    }

    handleUploadMediaItemToCurrProjectExp(filesArray);
    enableLoader(false);
  };

  const updateVendorExperience = async () =>{
      const payload = {
      filesArray: uploadedImages,
      gstn: gstn
    }

    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/experiences/link-upload-projects-media",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );

    if (result.data.status === "SUCCESS") {
      setShowNotification(true);
      setNotificationMessage("files uploaded sucessfully")
      setReloadUpdate(true);
    }
    else {
      setShowNotification(true);
      setNotificationMessage("Error in uploading files files")
    }
    setUploadedImages([]);
  }

  return hasAccess ? (
    <>
      <div className="container-fluid scrollable-container">
        <SnackBarNotification
          severity={"error"}
          message={notificationMessage}
          openNotification={showNotification}
          closeSnackbar={() => setShowNotification(false)}
        />
        <Dialog
          open={openWarningModal}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Are you Sure you want to remove " + `${modalShowData.label}` + " Experience?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => upDateVendorExperienceDataBulk(experiences, updateId, gstn, editChipsData)}
              color="primary"
              autoFocus
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.peopleInfoContainer}>
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="row mt-4">
            <InputLabel className={classes.subHeader}>Client Company Name</InputLabel>
            <div className="col text-right">
              <Button
                variant="contained"
                className={`ml-2 ${prompt.isDataChanged? "new_add_button_disabled": "new_add_button"} mb-2`}
                startIcon={<AddIcon />}
                onClick={addExperience}
                disabled={prompt.isDataChanged? true: false}
              >
                Add
              </Button>
            </div>
            <ChipsArray
              ChipsArray={experiences}
              setChipsArray = {setExperiencesDataReorder}
              drag = {true}
              deletable={true}
              onDelete={removeExperiences}
              onClick={editExperienceData}
            ></ChipsArray>
          </div>
          {editChips ? (
            <div className="mt-4">
              <div className="row">
                <div className="col">
                  <InputLabel className={classes.subHeader}>Client Company Name</InputLabel>
                  <TextField
                    id="clientCompanyName"
                    value={editChipsData?.clientCompanyName || editChipsData?.companyProfile || ""}
                    variant="outlined"
                    disabled={false}
                    fullWidth
                    onChange={(e) => {
                      prompt.setIsDataChanged(true);
                      setFieldName(e, editChipsData)
                    }}
                    style={{border: isClientCompanyNameEmpty ? "1px solid #BE000B": ""}}
                    />
                  {isClientCompanyNameEmpty && (
                    <span
                      style={{
                        color: "#BE000B",
                        textAlign: "left",
                        paddingTop: "6px",
                        paddingBottom: "0px",
                        fontStyle: "italic",
                      }}
                    >
                      * required field
                    </span>
                  )}
                </div>
                <div className="col">
                  <InputLabel className={classes.subHeader}>City</InputLabel>
                  <TextField
                    id="city"
                    value={editChipsData?.city || ""}
                    variant="outlined"
                    disabled={false}
                    fullWidth
                    onChange={(e) => {
                      prompt.setIsDataChanged(true);
                      setFieldName(e, editChipsData)
                    }}
                    style={{border: isCityEmpty ? "1px solid #BE000B": ""}}
                  />
                  {isCityEmpty && (
                    <span
                      style={{
                        color: "#BE000B",
                        textAlign: "left",
                        paddingTop: "6px",
                        paddingBottom: "0px",
                        fontStyle: "italic",
                      }}
                    >
                      * required field
                    </span>
                  )}
                </div>
              </div>
              <InputLabel className={classes.projectDescriptionSectionTitie}>
                <Flex>
                Project Description
                </Flex>
              </InputLabel>
              <div>
                {/* commented for future reference */}
                {/* <TextField
                  id="description"
                  multiline
                  value={editChipsData?.description || ""}
                  variant="outlined"
                  disabled={false}
                  rows={4}
                  fullWidth
                  onChange={(e) => setFieldName(e, editChipsData)}
                /> */}
                {/* Rich Text Editor */}
                <div className={classes.reactQuillSuperContainer}>
                  <div className="text-quill-wrapper">
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      preserveWhitespace
                      style={{border: isDescriptionEmpty ? "1px solid #BE000B": ""}}
                      value={currExpDescription || ""}
                      onChange={(value, delta, source, editor) => {
                        prompt.setIsDataChanged(true);
                        const userInputText = editor?.getText();
                        let eventObject = {
                          target: { id: "description", value: "" } 
                        };
                        if (EMPTY_TEXT_VALUES?.includes(userInputText.trim())) {
                          /** when text is empty or user types spaces **/
                          eventObject.target.value = "";
                          setCurrExpDescription(eventObject.target.value.trim());
                          setIsDescriptionEmpty(true);
                        } else {
                          let formattedValue = cleanFormatting({
                            stateVal: editChipsData?.description || "",
                            content: value,
                          });
                          eventObject.target.value = formattedValue;
                          setCurrExpDescription(eventObject.target.value.trim());
                          setIsDescriptionEmpty(false);
                        }
                      }}
                    />
                  </div>
                  {isDescriptionEmpty && (
                    <span
                      style={{
                        color: "#BE000B",
                        textAlign: "left",
                        paddingTop: "6px",
                        paddingBottom: "0px",
                        fontStyle: "italic",
                      }}
                    >
                      * required field: Please enter minimum 50 characters
                    </span>
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col ml-4">
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <InputLabel className={classes.subHeader}>Project Document</InputLabel>
                      </div>
                      <FileStorage
                        data={!_.isEmpty(editChipsData?.document) ? [formattedDocument(editChipsData.document)] : []}
                        setLocalState={(file, fileName) => {
                          prompt.setIsDataChanged(true);
                          handleFileChange(file, fileName)
                        }}
                        removeDocuments={(e, element) => removeProjectDocument(e, element)}
                      />
                    </div>
                    <div className="col">
                      <div className="row">
                        <InputLabel className={classes.subHeader}>Project Cost</InputLabel>
                      </div>
                      <div className="row project-cost-dropdown">
                        <Autocomplete
                          id="projectCost"
                          disablePortal
                          options={dropDownData.ProjectCost}
                          value={editChipsData?.projectCost || ""}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField variant="outlined" {...params} label={""} placeholder="Search..." />
                          )}
                          onChange={(e, value) => {
                            prompt.setIsDataChanged(true);
                            const eventObject = { target: { id: "projectCost", value: value.value } };
                            setFieldName(eventObject, editChipsData);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row ml-2">
                    <TextField
                      id="document"
                      type="text"
                      variant="outlined"
                      placeholder="Project Document"
                      value={prjDocument?.name || ""}
                      size="small"
                      InputProps={{
                        style: { textAlign: "center" },
                      }}
                    />
                    <input
                      accept="application/pdf,image/jpeg"
                      style={{ display: "none" }}
                      id="prjDocument"
                      onChange={uploadFile}
                      type="file"
                      multiple="false"
                    />
                    <label htmlFor="prjDocument">
                      <IconButton color="primary" component="span">
                        <PublishIcon />
                      </IconButton>
                    </label>
                    {prjDocument.name && (
                      <Button
                        variant="contained"
                        className="ml-2 add_button mb-2"
                        startIcon={<AddIcon />}
                        onClick={() => createPermanentLinkAndAdd(prjDocument.documentFile, prjDocument.name)}
                      >
                        Add
                      </Button>
                    )}
                  </div> */}
                  {/* <Typography variant="caption" color="dark" gutterBottom component="div">
                  Supports .pdf, .docx, .jpeg, .png formats, max 20mb
                </Typography> */}
                  {/* {editChipsData?.document?.name ? (
                    <Chip
                      label={
                        editChipsData?.document?.name?.length > 15
                          ? editChipsData?.document?.name?.substring(0, 12) + "..."
                          : editChipsData?.document?.name
                      }
                      icon={<InsertDriveFileIcon />}
                      className="document-chip"
                      size="small"
                      color="info"
                      onClick={() => openPrjDocument(editChipsData.document.documentFile.fileUrl)}
                      onDelete={() => {
                        setEditChipsData((currData) => ({ ...currData, document: {} }));
                      }}
                    />
                  ) : null} */}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <div className="row mt-4">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="col">
                        <InputLabel className={classes.subHeader}>Project Start Date</InputLabel>
                        <KeyboardDatePicker
                          margin="normal"
                          id="projStartDate"
                          format="MM-yyyy"
                          fullWidth
                          value={
                            (moment(editChipsData?.projStartDate, 'YYYY-MM',true).isValid() || moment(editChipsData?.projStartDate, 'YYYY-MM-DD',true).isValid())
                              ? moment(editChipsData?.projStartDate, "YYYY-MM").toDate()
                              : null
                          }
                          onChange={(date) => {
                            prompt.setIsDataChanged(true);
                            setStartExperienceDates(date, editChipsData)
                          }}
                          views={['month', 'year']}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </div>
                      <div className="col">
                        <InputLabel className={classes.subHeader}>Project End Date</InputLabel>
                        <KeyboardDatePicker
                          margin="normal"
                          id="projEndDate"
                          format="MM-yyyy"
                          fullWidth
                          value={
                            (moment(editChipsData?.projEndDate, 'YYYY-MM',true).isValid() || moment(editChipsData?.projEndDate, 'YYYY-MM-DD',true).isValid())
                              ? moment(editChipsData?.projEndDate, "YYYY-MM").toDate()
                              : null
                          }
                          onChange={(date) => {
                            prompt.setIsDataChanged(true);
                            setEndExperienceDates(date, editChipsData)
                          }}
                          views={['month', 'year']}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="col mt-4">
                  <InputLabel className={classes.subHeader}>Client Industry</InputLabel>
                  <Autocomplete
                    id="clientIndustry"
                    className="mt-10"
                    disablePortal
                    options={dropDownData.industries}
                    value={editChipsData?.clientIndustry || ""}
                    renderOption={(props, option) => <li {...props}>{option.value}</li>}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField variant="outlined" {...params} label={""} placeholder="Search..."/>
                    )}
                    onChange={(e, value) => {
                      const eventObject = { target: { id: "clientIndustry", value: value.value } };
                      prompt.setIsDataChanged(true);
                      setFieldName(eventObject, editChipsData);
                    }}
                  />
                </div>
              </div>
              <div className="row mt-4">
                  <div className="col">
                    <Flex gap={"4px"} alignItems={"center"}>
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<PhotoLibraryRoundedIcon />}
                      onClick={() => setShowLinkGalleryModal(true) }
                    >
                      Link Gallery Media
                    </Button>
                    <Typography>
                        {ImageAttactedCount(editChipsData, associatedMediaItems) || 0} images added
                    </Typography>
                    </Flex>
                  </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col text-right">
          {editChips ? (
            <div className="save-button-container">
              <Button
                variant="contained"
                color="primary"
                className="save-button"
                disabled={isDescriptionEmpty || isClientCompanyNameEmpty || isCityEmpty ? true: false}
                startIcon={<CloudUploadIcon />}
                onClick={() => {
                  if (currExpDescription.trim().length <= 0) {
                    setIsDescriptionEmpty(true);
                  }
                  if(editChipsData.clientCompanyName===undefined || editChipsData.clientCompanyName.trim().length <= 0){
                    setClientCompanyNameEmpty(true)
                  } 
                  if(editChipsData.city===undefined || editChipsData.city.trim().length <= 0){
                    setCityEmpty(true);
                  }
                  else {
                    prompt.setIsDataChanged(false);
                    saveChipsData(
                      editChipsData,
                      updateId,
                      newDataMode,
                      gstnValue
                    );
                    uploadedImages.length>0 && updateVendorExperience();
                  }
                }}
              >
                Save
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      {showLinkGalleryModal && (
        <LinkAvailableGalleryItemsModal
          title={`PROJECT IMAGES`}
          showModal={showLinkGalleryModal}
          parent={"experiences"}
          handleImageUpload={handleImageUpload}
          handleCloseModal={handleCloseLinkGalleryModal}
          currEntityObj={editChipsData}
          associatedMediaItems={associatedMediaItems}
          handleAddMediaItemToCurrEntityObj={handleAddMediaItemToCurrProjectExp}
          handleRemoveMediaItemFromCurrEntityObj={handleRemoveMediaItemFromCurrProjectExp}
          setIsDataChanged={prompt.setIsDataChanged}
        />
      )}
    </>
  ) : (
    <NoAccess></NoAccess>
  );
};

export default VendorExperienceUpdate;
