import React, { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import {
  IMachine,
  IPlantMachineDetail,
  ManufCapabilitiesTableProps,
  BulkMachineCSVTemplate
} from "./ManufacturingTable.interface";
import {
  Box,
  Collapse,
  Button,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import PhotoLibraryRoundedIcon from "@material-ui/icons/PhotoLibraryRounded";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { notification } from "antd";

import {
  ALL_AVAILABLE_MACHINE_NAMES,
  FORMIK_MACHINE,
  FORMIK_PLANT,
  INDIAN_STATES,
  PLANT_DROPDOWN_OPTIONS,
} from "../ManufacturingTable/ManufacturingTable.constants";
import { AddOrEditPlantModal } from "../ManufacturingModals/AddOrEditPlantModal";
import { AddOrEditMachineModal } from "../ManufacturingModals/AddOrEditMachineModal";
import { getTagsDropdownOptions } from "container/tags/Tags_helper";
import axios from "axios";
import LocalStorage from "_services/LocalStorage";
import { NEO4J_END_POINTS } from "container/tags/constants";
import { blue } from "@material-ui/core/colors";
import { useManufacturingtableStyles } from "./ManufacturingTable.styles";
import { LinkAvailableGalleryItemsModal } from "../../../LinkAvailableGalleryItemsModal/LinkAvailableGalleryItemsModal";
import { getUrlFromMediaObject } from "../GalleryModal/galleryModal.handler";
import { ICompanyVideo, IGalleryImage } from "../GalleryModal/GalleryModal.interface";
import { IEntityObj } from "components/LinkAvailableGalleryItemsModal/LinkAvailableGalleryItemsModal.interface";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const ManufacturingTable = (props: ManufCapabilitiesTableProps) => {
  const {
    capabilitiesDetails,
    setCapabilitiesDetails,
    plantMachineDetails,
    setPlantMachineDetails,
    handlers,

    // modal related props
    showLinkGalleryModal,
    showAddOrEditMachineModal,
    showAddOrEditPlantModal,
    handleCloseModal,

    // form related props
    currPlantObj,
    setCurrPlantObj,
    currMachineObj,
    setCurrMachineObj,

    // machine gallery related props
    indexOfCurrPlant,
    indexOfCurrMachine,
    isFormAddNewOrEditExisting,
    setIsDataChanged,
    enableLoader,
  } = props;
  const classes = useManufacturingtableStyles();
  const csvFileInputRef = useRef<HTMLInputElement|null>(null);
  const [plantIndexForAddBulkMachine, setPlantIndexForAddBulkMachine] = React.useState<number|null>(null);
  const [tempPlantMachineDetailObject, setTempPlantMachineDetailObject] = React.useState<IPlantMachineDetail>({} as IPlantMachineDetail);
  const [openPlantAccordions, setOpenPlantAccordions] = React.useState<
    number[]
  >([0]);

  const [machineMakeOptions, setMachineMakeOptions] = React.useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const { gstn } = useParams();

  const handleDragEnd = (result: any, plantIndex: number) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const reorderedMachines = Array.from(plantMachineDetails[plantIndex]?.machines);
    const [movedItem] = reorderedMachines.splice(source.index, 1);
    reorderedMachines.splice(destination.index, 0, movedItem);
    plantMachineDetails[plantIndex].machines = reorderedMachines;
    setPlantMachineDetails(plantMachineDetails);
  };

  useEffect(() => {
    (async () => {
      if (machineMakeOptions.length === 0) {
        try {
          const resp = await axios({
            method: "GET",
            url: process.env.REACT_APP_API_URL + NEO4J_END_POINTS.OEM.TAGS,
            headers: { authorization: `Bearer ${LocalStorage.get("token")}` },
          });
          setMachineMakeOptions(
            resp?.data?.map((tagObj: { name: string }) => {
              return { label: tagObj.name, value: tagObj.name };
            })
          );
        } catch (err) {
          console.error("Unable to get options", err);
          setMachineMakeOptions([]);
        }
      }
    })();
  }, []);

  const handleOnChangeFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // upload file handler
    const file = (event.target as HTMLInputElement).files?.[0];
    Papa.parse(file, {
      complete: function(results: { data: Array<string[]> }) {
        const jsonDataArr = results?.data;
        const machinesFromCSV: IMachine[] = [];
        const machinesWithErrors: {
          csvRowNum: number;
          error: string;
        }[] = [];

        if (jsonDataArr?.length) {
          jsonDataArr?.forEach((rowDataArr, index) => {
            /**
             * skip first row,
             * and when row contains 6 values corresponding to each column */
            if (rowDataArr?.length === 6 && index !== 0) {
              // values
              const machineName = rowDataArr?.[0];
              const process = rowDataArr?.[1] || "";
              const number = rowDataArr?.[2];
              const make = rowDataArr?.[3] || "";
              const modelName = rowDataArr?.[4] || "";
              const specification = rowDataArr?.[5] || "";

              // ERRORS
              const ERRORS = {
                IS_MACHINE_NAME_ABSENT: !machineName,
                IS_NO_OF_MACHINES_NOT_A_NUMBER: !number ? false : !(/^\d+$/.test(number)),
              }

              if (!ERRORS.IS_MACHINE_NAME_ABSENT
                && !ERRORS.IS_NO_OF_MACHINES_NOT_A_NUMBER
              ) {
                machinesFromCSV.push({
                  name: machineName,
                  process,
                  number: Number(number) || 1,
                  make,
                  modelName,
                  specification,
                  gallery: [],
                  otherInfo: "",
                } as IMachine);
              } else if (ERRORS.IS_MACHINE_NAME_ABSENT) {
                machinesWithErrors.push({
                  csvRowNum: index + 1,
                  error: "Machine name is mandatory",
                });
              } else if (ERRORS.IS_NO_OF_MACHINES_NOT_A_NUMBER) {
                machinesWithErrors.push({
                  csvRowNum: index + 1,
                  error: "Machine Number should be a numberic value",
                });
              }
            }
          });
        }

        // add bulk machines
        if (handlers?.machine?.handleOnClickAddBulkMachinesToAPlant) {
          handlers?.machine?.handleOnClickAddBulkMachinesToAPlant({
            plantIndex: plantIndexForAddBulkMachine || 0,
            plantDetail: tempPlantMachineDetailObject,
            validMachinesFromCSV: machinesFromCSV,
            invalidMachinesFromCSV: machinesWithErrors,
          });

          // reset file input
          (event.target as any).value = null;
        }

        // resetting state
        setPlantIndexForAddBulkMachine(null);
        setTempPlantMachineDetailObject({} as IPlantMachineDetail);
      }
    });
  };

  /** Variables Related to linking media item to machine */
  const currMachineId = currMachineObj?._id || "";
  const galleryImages = capabilitiesDetails?.gallery || [];
  const companyVideos = capabilitiesDetails?.companyVideo || [];

  const filterImageByCategory = (
    galleryImages: IGalleryImage[],
    category: string
  ) => {
    const categoryImage = galleryImages.filter(
      (image) => image.category === category
    );
    return categoryImage;
  };

  const filterVideoByCategory = (
    companyVideos: ICompanyVideo[],
    category: string
  ) => {
    const categoryVideo = companyVideos.filter(
      (video) => video.category === category
    );
    return categoryVideo;
  };

  const associatedMediaItems = useMemo(() => {
    /** filter out media items that are associated with other machines,
     *  because one media item can only be associated with one machine */
    let allMediaItems = [
      ...filterImageByCategory(galleryImages, "Factory"),
      ...filterVideoByCategory(companyVideos, "Factory"),
    ];
    let mediaUrlsAlreadyAssociatedWithOtherMachines: string[] = [];
    for (let i = 0; i < plantMachineDetails.length; i++) {
      const existingPlant = plantMachineDetails[i];
      const existingPlantIdx = i;
      for (let j = 0; j < existingPlant.machines.length; j++) {
        const existingMachine = existingPlant.machines[j];
        const existingMachineIdx = j;
        if ((
          existingMachine?._id && currMachineId && existingMachine?._id === currMachineId
        ) || (
          indexOfCurrMachine === existingMachineIdx && indexOfCurrPlant === existingPlantIdx
        )) {
          /** Skip the current machine */
          continue;
        } else {
          /** Add all media items associated with other machines */
          if (existingMachine?.gallery) {
            existingMachine?.gallery?.forEach((mediaObj) => {
              const MEDIA_URL = getUrlFromMediaObject(mediaObj);
              if (MEDIA_URL) mediaUrlsAlreadyAssociatedWithOtherMachines.push(MEDIA_URL);
            });
          }
        }
      }
    }
    if (currMachineObj.gallery?.length) {
      const filteredGalleryItems = currMachineObj?.gallery.filter(
        (galleryItem) => !allMediaItems.some((mediaItem) => mediaItem.file?.fileName === galleryItem?.file?.fileName || mediaItem.file?.fileUrl === galleryItem?.file?.fileUrl)
      );
      
      allMediaItems = [...allMediaItems, ...filteredGalleryItems];
    }
    const availableMediaItems = allMediaItems.filter((mediaObj) => {
      const MEDIA_URL = getUrlFromMediaObject(mediaObj);
      const IS_CURR_MEDIA_URL_NOT_ASSOCIATED_WITH_ANY_MACHINE = !mediaUrlsAlreadyAssociatedWithOtherMachines.includes(MEDIA_URL)
      return IS_CURR_MEDIA_URL_NOT_ASSOCIATED_WITH_ANY_MACHINE;
    });

    return availableMediaItems || [];
    
  }, [currMachineObj?.gallery?.length]);

  const handleRemoveMediaItemFromCurrMachine = (mediaItemObj: IGalleryImage | ICompanyVideo) => {
    const MEDIA_URL = getUrlFromMediaObject(mediaItemObj);
    const newGallery = currMachineObj?.gallery?.filter((galleryItem) => {
      const galleryIteemURL = getUrlFromMediaObject(galleryItem);
      return galleryIteemURL !== MEDIA_URL;
    }) || [];
    setCurrMachineObj({ ...currMachineObj, gallery: newGallery });
    
    const updatedPlantMachineDetails = plantMachineDetails.map((existingPlant, existingPlantIdx) => {
      if (existingPlantIdx !== indexOfCurrPlant) return existingPlant;
      const updatedMachines = existingPlant?.machines?.map((existingMachine, existingMachineIdx) => {
        if (existingMachineIdx !== indexOfCurrMachine) return existingMachine;
        return { ...existingMachine, gallery: newGallery };
      }) || [];
      return { ...existingPlant, machines: updatedMachines };
    })
    /** Update machine gallery of existing machine in plantMachineDetails for POST API */
    setPlantMachineDetails(updatedPlantMachineDetails);
  };

  const handleAddMediaItemToCurrMachine = (mediaItemObj: IGalleryImage | ICompanyVideo) => {
    const newGallery = [...(currMachineObj?.gallery || []), mediaItemObj] || [];
    setCurrMachineObj({ ...currMachineObj, gallery: newGallery });
    const updatedPlantMachineDetails = plantMachineDetails.map((existingPlant, existingPlantIdx) => {
      if (existingPlantIdx !== indexOfCurrPlant) return existingPlant;
      const updatedMachines = existingPlant?.machines?.map((existingMachine, existingMachineIdx) => {
        if (existingMachineIdx === indexOfCurrMachine) existingMachine.gallery = newGallery;
        return existingMachine
      }) || [];
      return { ...existingPlant, machines: updatedMachines };
    });
    /** Update machine gallery of existing machine in plantMachineDetails for POST API */
    setPlantMachineDetails(updatedPlantMachineDetails);
  };

  const handleUploadMediaItemToCurrMachine = (mediaItemObj: IGalleryImage[]) => {
    const newGallery = [...(currMachineObj?.gallery || []), ...mediaItemObj] || [];
    setCurrMachineObj({ ...currMachineObj, gallery: newGallery });
    setCapabilitiesDetails({...capabilitiesDetails, gallery: [...capabilitiesDetails.gallery, ...mediaItemObj]});
    const updatedPlantMachineDetails = plantMachineDetails.map((existingPlant, existingPlantIdx) => {
      if (existingPlantIdx !== indexOfCurrPlant) return existingPlant;
      const updatedMachines = existingPlant?.machines?.map((existingMachine, existingMachineIdx) => {
        if (existingMachineIdx === indexOfCurrMachine) existingMachine.gallery = newGallery;
        return existingMachine
      }) || [];
      return { ...existingPlant, machines: updatedMachines };
    });
    setPlantMachineDetails(updatedPlantMachineDetails);
  };

  const handleUpload = async (
    file : any,
    fileName : string
  ) => {
    const data = new FormData();
    data.append("file", file);
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/upload",
      data,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result.data.status === "SUCCESS") {
      const permanentFileUploadResult = await axios.post(
        process.env.REACT_APP_BASE_URL + "/file-storage/permanent",
        result.data.data,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      );
      if (permanentFileUploadResult?.data?.status === "SUCCESS") {
        const file = permanentFileUploadResult.data.data;
        const finalFile: IGalleryImage = {
          file: file,
          imageName: fileName,
          category: "Factory",
          caption: fileName.split('.')[0],
        }
        return finalFile;
      } else {
        notification.info({
          top: 70,
          duration: 4,
          description: ``,
          message: "Error in getting permanent files",
        });
      }
    } else {
      notification.info({
        top: 70,
        duration: 4,
        description: ``,
        message: "Error in uploading files",
      });
    }
  };

  const handleImageUpload = async (e: { target: { files: any; }; }) => {
    enableLoader(true);
    const tempFile = e?.target?.files;
    const filesArray = []
    for (let i = 0; i < tempFile?.length; i++) {
      const file = tempFile[i];
      const fileName = file?.name;
      const filelink = await handleUpload(file, fileName);
      if (filelink) {
        filesArray.push(filelink)
      }else{
        notification.info({
          top: 70,
          duration: 4,
          description: ``,
          message: `Failed to upload file ${fileName}`,
        });
      }
    }
    handleUploadMediaItemToCurrMachine(filesArray);
    enableLoader(false);
  };

  return (
    <>
      {/* Plant Machine Details Table */}
      <Box component={Paper} className={classes.paper} width={"100%"}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          gridGap={"1rem"}
          alignItems={"center"}
          style={{ margin: "0.5rem", paddingTop: "0.5rem" }}
        >
          <Button
            size={"small"}
            color={"primary"}
            variant={"contained"}
            onClick={() => {
              if (handlers?.plant?.handleOnClickAddNewPlant) {
                handlers?.plant?.handleOnClickAddNewPlant({
                  plantDetail: {} as IPlantMachineDetail,
                  plantIndex: plantMachineDetails?.length || 0,
                });
              }
            }}
          >
            Add New Plant
          </Button>
          <input
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            ref={csvFileInputRef}
            onChange={(event) => {
              handleOnChangeFileInput(event);
            }}
          />
        </Box>
        {Boolean(plantMachineDetails?.length > 0) && (
          <>
            {plantMachineDetails.map((plantMachineDetailObject, plantIndex) => {
              if (plantMachineDetailObject?.delete) return <></>;

              const isPlantAccordionOpen =
                openPlantAccordions?.includes(plantIndex);
              const plantState =
                plantMachineDetailObject?.location?.state || "";
              const plantCity = plantMachineDetailObject?.location?.city || "";
              const plantTotalAreaValue =
                plantMachineDetailObject?.totalArea?.value || "";
              const plantTotalAreaUnit =
                plantMachineDetailObject?.totalArea?.unit || "";
              const plantWorkshopAreaValue =
                plantMachineDetailObject?.workshopArea?.value || "";
              const plantWorkshopAreaUnit =
                plantMachineDetailObject?.workshopArea?.unit || "";
              const plantCapacityValue =
                plantMachineDetailObject?.capacity?.value || "";
              const plantCapacityUnit =
                plantMachineDetailObject?.capacity?.unit || "";
              const plantOtherInfo = plantMachineDetailObject?.otherInfo.split('|') || "";

              const PLANT_CELL_ITEMS = [
                {
                  value: (() => {
                    if (plantCity) {
                      return plantState
                        ? `${plantCity}, ${plantState}`
                        : plantCity;
                    }
                    return plantState || "";
                  })(),
                  // width: TABLE_HEADER_CONSTANTS.PLANT.LOCATION.width,
                },
                {
                  value: plantTotalAreaValue
                    ? `${plantTotalAreaValue} ${plantTotalAreaUnit || "other"}`
                    : "",
                  // width: TABLE_HEADER_CONSTANTS.PLANT.TOTAL_AREA.width,
                },
                {
                  value: plantWorkshopAreaValue
                    ? `${plantWorkshopAreaValue} ${
                        plantWorkshopAreaUnit || "other"
                      }`
                    : "",
                  // width: TABLE_HEADER_CONSTANTS.PLANT.WORKSHOP_AREA.width,
                },
                {
                  value: plantCapacityValue
                    ? `${plantCapacityValue} ${plantCapacityUnit || "other"}`
                    : "",
                  // width: TABLE_HEADER_CONSTANTS.PLANT.CAPACITY.width,
                },
                {
                  value: plantOtherInfo || "",
                  // width: TABLE_HEADER_CONSTANTS.PLANT.OTHER_INFORMATION.width,
                },
              ];

              return (
                <Box key={plantIndex} className={classes.plantTable}>
                  {/* Plant table Header */}
                  <Box
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={(event) => {
                      setOpenPlantAccordions(
                        openPlantAccordions.includes(plantIndex)
                          ? openPlantAccordions.filter(
                              (index) => index !== plantIndex
                            )
                          : [...openPlantAccordions, plantIndex]
                      );
                    }}
                  >
                    <TableCell
                      className={clsx(
                        classes.plantActionButtonsCell,
                        classes.tableHeaderCell
                      )}
                    >
                      Actions
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.plantLocationCell,
                        classes.tableHeaderCell
                      )}
                    >
                      Location*
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.plantTotalAreaCell,
                        classes.tableHeaderCell
                      )}
                    >
                      Total Area
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.plantWorkshopAreaCell,
                        classes.tableHeaderCell
                      )}
                    >
                      Workshop Area
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.plantCapacityCell,
                        classes.tableHeaderCell
                      )}
                    >
                      Capacity
                    </TableCell>
                    <TableCell
                      className={clsx(
                        classes.plantOtherInfoCell,
                        classes.tableHeaderCell
                      )}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Other Information
                      {isPlantAccordionOpen ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </TableCell>
                  </Box>

                  <Collapse
                    in={isPlantAccordionOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <>
                      {/* Plant table Row */}
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <TableCell
                          scope="row"
                          component="th"
                          className={clsx(
                            classes.plantActionButtonsCell,
                            classes.plantTableRowCell
                          )}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                          }}
                        >
                          <>
                            <EditRoundedIcon
                              className={classes.hoverableButton}
                              onClick={() => {
                                if (
                                  handlers?.plant?.handleOnClickEditPlant
                                ) {
                                  handlers?.plant?.handleOnClickEditPlant(
                                    {
                                      plantDetail:
                                        plantMachineDetailObject,
                                      plantIndex,
                                    }
                                  );
                                }
                              }}
                            />
                            <DeleteRoundedIcon
                              style={{ color: "red" }}
                              className={classes.hoverableButton}
                              onClick={() => {
                                /** Delete Plant */
                                if (
                                  handlers?.plant
                                    ?.handleOnClickDeletePlant
                                ) {
                                  handlers?.plant?.handleOnClickDeletePlant(
                                    {
                                      plantDetail:
                                        plantMachineDetailObject,
                                      plantIndex,
                                    }
                                  );
                                }
                              }}
                            />
                          </>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={clsx(
                            classes.plantLocationCell,
                            classes.plantTableRowCell
                          )}
                        >
                          {PLANT_CELL_ITEMS[0].value}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.plantTotalAreaCell,
                            classes.plantTableRowCell
                          )}
                        >
                          {PLANT_CELL_ITEMS[1].value}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.plantWorkshopAreaCell,
                            classes.plantTableRowCell
                          )}
                        >
                          {PLANT_CELL_ITEMS[2].value}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.plantCapacityCell,
                            classes.plantTableRowCell
                          )}
                        >
                          {PLANT_CELL_ITEMS[3].value}
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.plantOtherInfoCell,
                            classes.plantTableRowCell
                          )}
                        >
                          {
                            Array.isArray(PLANT_CELL_ITEMS[4].value) ? <ul>{PLANT_CELL_ITEMS[4].value.map((item) =>item.trim() && <li>{item}</li>)}</ul> : PLANT_CELL_ITEMS[4].value
                          }
                        </TableCell>
                      </Box>

                      <Box
                        style={{
                          gap: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "0.5rem",
                        }}
                      >
                        <Box
                          width={"100%"}
                          display={"flex"}
                          gridGap={"1rem"}
                          alignItems={"center"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          {/* Add New Machine Button */}
                          <Button
                            size={"small"}
                            variant={"outlined"}
                            color={"primary"}
                            onClick={() => {
                              if (handlers?.machine?.handleOnClickAddNewMachine) {
                                handlers?.machine?.handleOnClickAddNewMachine({
                                  machine: {} as IMachine,
                                  plantIndex,
                                  machineIndex:
                                    plantMachineDetailObject?.machines?.length ||
                                    0,
                                });
                              }
                            }}
                          >
                            Add New Machine
                          </Button>

                          {/* Add Bulk Machines Container */}
                          <Box
                            style={{
                              gap: "0.5rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              size={"small"}
                              color={"primary"}
                              variant={"outlined"}
                              onClick={() => {
                                // Download CSV Template
                                const JSON_TEMPLATE_DATA = [
                                  {
                                    name: "(mandatory) - machine_name",
                                    process: "test_process",
                                    number: 1,
                                    make: "test_make",
                                    modelName: "test_model_name",
                                    specification: "test_specification",
                                  }
                                ];
                                const CSV_FILE_STRING = Papa.unparse(JSON_TEMPLATE_DATA);
                                if (CSV_FILE_STRING) {
                                  const blob = new Blob([CSV_FILE_STRING], {type: "text/csv;charset=utf-8"});
                                  saveAs(blob, "bulk_update_machines_tempalate.csv");
                                }
                              }}
                              startIcon={<GetAppIcon />}
                            >
                              Bulk Machines CSV Template
                            </Button>
                            <Button
                              size={"small"}
                              color={"primary"}
                              variant={"outlined"}
                              onClick={() => {
                                let MachinesObj: BulkMachineCSVTemplate[] = [];
                                plantMachineDetailObject.machines.map(
                                  (item) => {
                                    const {
                                      _id,
                                      gallery,
                                      otherInfo,
                                      name,
                                      process,
                                      number,
                                      make,
                                      modelName,
                                      specification,
                                    } = item;
                                    const CsvMachineFormatObj: BulkMachineCSVTemplate =
                                      {
                                        name,
                                        process,
                                        number,
                                        make,
                                        modelName,
                                        specification,
                                      };
                                    MachinesObj.push(CsvMachineFormatObj);
                                  }
                                );
                                const CSV_FILE_STRING = Papa.unparse(MachinesObj);
                                if (CSV_FILE_STRING) {
                                  const blob = new Blob([CSV_FILE_STRING], {type: "text/csv;charset=utf-8"});
                                  saveAs(blob, `bulk_machine_data_${gstn}.csv`);
                                }
                              }}
                              startIcon={<GetAppIcon />}
                            >
                              Download Bulk Machines CSV
                            </Button>
                            <Button
                              size={"small"}
                              variant="outlined"
                              color="primary"
                              component="span"
                              style={{textAlign: "center"}}
                              onClick={() => {
                                  /** Delete Machines */
                                  if (
                                    handlers?.machine
                                      ?.handleOnClickBulkDeleteMachine
                                  ) {
                                    handlers?.machine?.handleOnClickBulkDeleteMachine(
                                      {
                                        plantIndex,
                                      }
                                    );
                                  }
                              }}
                            >
                                Delete Bulk Machines
                            </Button>
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                size={"small"}
                                variant="outlined"
                                color="primary"
                                component="span"
                                startIcon={<PublishIcon />}
                                onClick={() => {
                                  setPlantIndexForAddBulkMachine(plantIndex);
                                  setTempPlantMachineDetailObject(plantMachineDetailObject);
                                  csvFileInputRef?.current?.click();
                                }}
                              >
                                Add Bulk Machines(CSV)
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "0.5rem",
                          fontStyle: "italic",
                          border: "1px dashed #000",
                          borderRadius: "0.25rem",
                          padding: "0.25rem 0.75rem",
                          justifySelf: "flex-end",
                          margin: "0 0.5rem 0.5rem 0.5rem",
                        }}
                      >
                        <Box
                          style={{
                            backgroundColor: blue[100],
                            borderRadius: "100%",
                            height: "0.8rem",
                            width: "0.8rem",
                          }}
                        />
                        machine name not in the existing list
                      </Box>

                      {/* Machine Table */}
                      <Box>
                        {/* Machine Table Header */}
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <TableCell
                            className={clsx(
                              classes.tableHeaderCell,
                              classes.machineActionButtonsCell
                            )}
                          ></TableCell>
                          <TableCell
                            className={clsx(
                              classes.tableHeaderCell,
                              classes.machineProcessCell
                            )}
                          >
                            Process
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.tableHeaderCell,
                              classes.machineNameCell
                            )}
                          >
                            Machine Name*
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.tableHeaderCell,
                              classes.machineNumberCell
                            )}
                          >
                            Number
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.tableHeaderCell,
                              classes.machineModelNameCell
                            )}
                          >
                            Model Name
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.tableHeaderCell,
                              classes.machineModelMakeCell
                            )}
                          >
                            Model Make
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.tableHeaderCell,
                              classes.machineSpecificationCell
                            )}
                          >
                            Specification
                          </TableCell>
                          {/* <TableCell
                            className={clsx(
                              classes.tableHeaderCell,
                              classes.machineOtherInformationCell
                            )}
                          >
                            Other Information
                          </TableCell> */}
                        </Box>

                        {/* Machine Table Rows */}
                        <DragDropContext onDragEnd={(result: any) => handleDragEnd(result, plantIndex)}>
                          <Droppable droppableId="machineTable">
                            {(provided: any) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{ display: "flex", flexDirection: "column" }}
                              >
                                {plantMachineDetailObject?.machines.map((machine, machineIndex) => {
                                  if (machine?.delete) return null;

                                  const MACHINE_CELL_ITEMS = [
                                    { value: machine.process },
                                    { value: machine.name },
                                    { value: machine.number },
                                    { value: machine.modelName },
                                    { value: machine.make },
                                    { value: machine.specification.split("|") },
                                  ];

                                  const IS_CURRENT_MACHINE_NAME_NOT_PRESENT_IN_ALL_MACHINE_NAMES =
                                    !(ALL_AVAILABLE_MACHINE_NAMES as string[]).includes(machine?.name as typeof ALL_AVAILABLE_MACHINE_NAMES[number]);

                                  return (
                                    <Draggable
                                      key={machineIndex}
                                      draggableId={`machine-${machineIndex}`}
                                      index={machineIndex}
                                    >
                                      {(provided: any) => (
                                        <Box
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "8px",
                                          }}
                                        >
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            className={clsx(
                                              classes.machineTableRowCell,
                                              classes.machineActionButtonsCell
                                            )}
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              overflow: "hidden",
                                            }}
                                          >
                                            <>
                                              <PhotoLibraryRoundedIcon
                                                className={classes.hoverableButton}
                                                onClick={() =>
                                                  handlers?.machine?.handleOnClickViewMachineGallery &&
                                                  handlers?.machine?.handleOnClickViewMachineGallery({
                                                    machine,
                                                    plantIndex,
                                                    machineIndex,
                                                  })
                                                }
                                              />
                                              <EditRoundedIcon
                                                className={classes.hoverableButton}
                                                onClick={() => {
                                                  if (handlers?.machine?.handleOnClickEditMachine) {
                                                    handlers?.machine?.handleOnClickEditMachine({
                                                      machine,
                                                      machineIndex,
                                                      plantIndex,
                                                    });
                                                  }
                                                }}
                                              />
                                              <DeleteRoundedIcon
                                                style={{ color: "red" }}
                                                className={classes.hoverableButton}
                                                onClick={() => {
                                                  if (
                                                    handlers?.machine?.handleOnClickDeleteMachine
                                                  ) {
                                                    handlers?.machine?.handleOnClickDeleteMachine({
                                                      machine,
                                                      plantIndex,
                                                      machineIndex,
                                                    });
                                                  }
                                                }}
                                              />
                                            </>
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            className={clsx(
                                              classes.machineTableRowCell,
                                              classes.machineProcessCell
                                            )}
                                          >
                                            {MACHINE_CELL_ITEMS[0].value}
                                          </TableCell>
                                          <TableCell
                                            className={clsx(
                                              classes.machineTableRowCell,
                                              classes.machineNameCell
                                            )}
                                            style={{
                                              backgroundColor:
                                                machine?.name &&
                                                  IS_CURRENT_MACHINE_NAME_NOT_PRESENT_IN_ALL_MACHINE_NAMES
                                                  ? blue[100]
                                                  : "",
                                            }}
                                          >
                                            {MACHINE_CELL_ITEMS[1].value}
                                          </TableCell>
                                          <TableCell
                                            className={clsx(
                                              classes.machineTableRowCell,
                                              classes.machineNumberCell
                                            )}
                                          >
                                            {MACHINE_CELL_ITEMS[2].value}
                                          </TableCell>
                                          <TableCell
                                            className={clsx(
                                              classes.machineTableRowCell,
                                              classes.machineModelNameCell
                                            )}
                                          >
                                            {MACHINE_CELL_ITEMS[3].value}
                                          </TableCell>
                                          <TableCell
                                            className={clsx(
                                              classes.machineTableRowCell,
                                              classes.machineModelMakeCell
                                            )}
                                          >
                                            {MACHINE_CELL_ITEMS[4].value}
                                          </TableCell>
                                          <TableCell
                                            className={clsx(
                                              classes.machineTableRowCell,
                                              classes.machineSpecificationCell
                                            )}
                                          >
                                            {Array.isArray(MACHINE_CELL_ITEMS[5].value) ? <ul>{MACHINE_CELL_ITEMS[5].value.map((item) => item.trim() && <li>{item}</li>)}</ul> : MACHINE_CELL_ITEMS[5].value}
                                          </TableCell>
                                        </Box>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Box>
                    </>
                  </Collapse>
                </Box>
              );
            })}
          </>
        )}
      </Box>

      {/* Modal for Plant and Machine Form */}
      {showAddOrEditPlantModal ? (
        <AddOrEditPlantModal
          currPlantObj={currPlantObj}
          setCurrPlantObj={setCurrPlantObj}
          handleCloseModal={handleCloseModal}
          plantMachineDetails={plantMachineDetails}
          setPlantMachineDetails={setPlantMachineDetails}
          showAddOrEditPlantModal={showAddOrEditPlantModal}
          indexOfCurrPlant={indexOfCurrPlant}
          isFormAddNewOrEditExisting={isFormAddNewOrEditExisting}
          setIsDataChanged={setIsDataChanged}
        />
      ) : showAddOrEditMachineModal ? (
        <AddOrEditMachineModal
          currPlantObj={currPlantObj}
          currMachineObj={currMachineObj}
          setCurrMachineObj={setCurrMachineObj}
          handleCloseModal={handleCloseModal}
          plantMachineDetails={plantMachineDetails}
          setPlantMachineDetails={setPlantMachineDetails}
          showAddOrEditMachineModal={showAddOrEditMachineModal}
          machineMakeOptions={machineMakeOptions}
          indexOfCurrPlant={indexOfCurrPlant}
          indexOfCurrMachine={indexOfCurrMachine}
          isFormAddNewOrEditExisting={isFormAddNewOrEditExisting}
          setIsDataChanged={setIsDataChanged}
        />
      ) : showLinkGalleryModal ? (
        <LinkAvailableGalleryItemsModal 
          title={`MACHINE IMAGES`}
          showModal={showLinkGalleryModal}
          handleCloseModal={handleCloseModal}
          currEntityObj={currMachineObj as IEntityObj}
          associatedMediaItems={associatedMediaItems}
          handleAddMediaItemToCurrEntityObj={handleAddMediaItemToCurrMachine}
          handleRemoveMediaItemFromCurrEntityObj={handleRemoveMediaItemFromCurrMachine}
          setIsDataChanged={setIsDataChanged}
          handleImageUpload={handleImageUpload}
        />
      ) : ( 
        <></>
      )}
    </>
  );
};
