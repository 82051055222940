import { JOB_STATUS } from "container/JobRequests/Constants/constants";
import { CLIENT_REQUEST_TYPES } from ".";
import { ICompanyOption } from "../interfaces/Company.interface";

export const ActionCreators: any = {
  getAllCompanies: () => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.CLIENT_REQUEST_GET_COMPANIES_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.get("get_all_companies"),
  }),

  getAllPlantsOfCompany: (clientCompanyEmail: string) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.CLIENT_REQUEST_GET_COMPANYS_PLANTS_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.get(`get_all_plants_of_company/?clientCompanyEmail=${clientCompanyEmail}`),
  }),
  sendJobRequestFormEmail: (data: any) => ({
    types: [
      CLIENT_REQUEST_TYPES.EMAIL_REQUESTED,
      CLIENT_REQUEST_TYPES.EMAIL_SUCCESS,
      CLIENT_REQUEST_TYPES.EMAIL_FAILURE,
    ],
    promise: (client: any) =>
      client.post("send_job_request_form_email",
        {
          data
        },
      )
  }),

  createProject: (data: any) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.CLIENT_REQUEST_CREATE_PROJECT_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) =>
      client.post("create_project", {
        data,
      }),
  }),

  getAllProjectsOfCompany: (clientCompanyId: string) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.CLIENT_REQUEST_GET_PROJECTS_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.get(`get_project_of_company/${clientCompanyId}`),
  }),

  getCompanyProjectNames: (clientCompanyId: string) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.CLIENT_REQUEST_GET_PROJECT_NAMES_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.get(`get_project_names_of_company/${clientCompanyId}`),
  }),
  
  getVendorRequestForCompany: (companyEmail: string, from: string, to: string) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.GET_VENDOR_REQUEST_FOR_COMPANY_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.get(`get_vendor_request_for_company/?from=${from}&to=${to}&company=${companyEmail}`),
  }),

  getRFQForProject: (companyEmail: string, from: string, to: string) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.GET_RFQ_FOR_PROJECT_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.get(`get_rfq_for_project/?from=${from}&to=${to}&projectId=${companyEmail}`),
  }),

  getVendorRequestCsvDataForCompany: (companyEmail: string, from: string, to: string) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.GET_VENDOR_REQUEST_CSV_DATA_FOR_COMPANY_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.get(`get_vendor_request_csv_data_for_company/?from=${from}&to=${to}&company=${companyEmail}`),
  }),

  getUsersList: (input: string) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.GET_USERS_LIST_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.get(`get_all_users_list/?search=${input}`),
  }),

  getProjectDetails: (projectId: string) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.GET_PROJECT_DETAILS_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.get(`get_project_details/${projectId}`),
  }),

  addOrRemoveUsersFromRoles: (data: any) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.ADD_OR_REMOVE_USER_FROM_ROLE_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) => client.put("add_remove_users_from_roles", { data }),
  }),

  setSelectedCompany: (company: ICompanyOption) => ({
    type:
      CLIENT_REQUEST_TYPES.SET_SELECTED_COMPANY,
      payload: company
  }),

  setSelectedProject: (company: ICompanyOption) => ({
    type:
      CLIENT_REQUEST_TYPES.SET_SELECTED_PROJECT,
      payload: company
  }),

//*** Vendor Request Actions ***//
  getJobRequestData: (id: string) => ({
      types: [
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
        CLIENT_REQUEST_TYPES.GET_JOB_REQUEST_DETAILS_SUCCESS,
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
      ],
      promise: (client: any) => client.get(`get_job_request_details/${id}`, {},),
  }),


  setJobRequestData: (payload: { key: string; value: any }) => ({
    type: CLIENT_REQUEST_TYPES.SET_JOB_REQUEST_DATA,
    payload,
  }),

  updateJobStatus: (data: { requestId: string; jobStatus: JOB_STATUS }) => {
    return {
      types: [
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
        CLIENT_REQUEST_TYPES.UPDATE_JOB_STATUS_SUCCESS,
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
      ],
      promise: (client: any) => client.post("update_job_status", { data }, "admin_backend"),
    };
  },

  createVendorRequest: (data: any) => {
    return {
      types: [
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
        CLIENT_REQUEST_TYPES.CREATE_VENDOR_REQUEST_SUCCESS,
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
      ],
      promise: (client: any) => client.post("create_vendor_request", { data }),
    };
  },

  updateJobRequest: (data: any) => {
    return {
      types: [
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
        CLIENT_REQUEST_TYPES.UPDATE_JOB_REQUEST_SUCCESS,
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
      ],
      promise: (client: any) => client.put("update_job_request", { data }),
    };
  },

  addRfqDocs: (data: any) => {
    return {
      types: [
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
        CLIENT_REQUEST_TYPES.ADD_RFQ_DOCS_SUCCESS,
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
      ],
      promise: (client: any) => client.patch("add_rfq_docs", { data }),
    };
  },

  removeRfqDocs: (data: any) => {
    return {
      types: [
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
        CLIENT_REQUEST_TYPES.REMOVE_RFQ_DOCS_SUCCESS,
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
      ],
      promise: (client: any) => client.patch("remove_rfq_docs", { data }),
    };
  },

  getPqrTemplates: () => {
    return {
      types: [
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
        CLIENT_REQUEST_TYPES.GET_PQR_TEMPLATES_SUCCESS,
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
      ],
      promise: (client: any) => client.get("get_job_pqr_templates"),
    };
  },

  generateAutoPQR: (data: any) => {
    return {
      types: [
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
        CLIENT_REQUEST_TYPES.GENERATE_AUTO_PQR_SUCCESS,
        CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
      ],
      promise: (client: any) => client.post("generate_auto_pqr", { data }),
    };
  },

//------------------------//

//*** Job Monetization Actions ***//

  updateVendorMonetizationValue: (payload: { idx: number, data: any; }) => {
    return {
      type: CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_VALUE,
      payload
    };
  },

  updateVendorMonetization: (data: any) => {
    return {
      types: [
        CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_REQUESTED,
        CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_SUCCESS,
        CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_FAILURE,
      ],
      promise: (client: any) => client.patch("update_job_monetization", { data }),
    };
  },

  sendAgreementToVendor: (data: any) => ({
    types: [
      CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_REQUESTED,
      CLIENT_REQUEST_TYPES.SEND_AGREEMENT_TO_VENDOR_SUCCESS,
      CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_FAILURE,
    ],
    promise: (client: any) => client.post("send_agreement_to_vendor", { data }),
  }),

  generateAgreement: (id: string) => ({
    types: [
      CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_REQUESTED,
      CLIENT_REQUEST_TYPES.GENERATE_PRICING_AGREEMENT_SUCCESS,
      CLIENT_REQUEST_TYPES.UPDATE_VENDOR_MONETIZATION_FAILURE,
    ],
    promise: (client: any) => client.post(`generate_agreement/${id}`),
  }),

//------------------------//

//RESET
  resetProjectSuccess: (data: any) => ({
    type: CLIENT_REQUEST_TYPES.RESET_CREATE_PROJECT_SUCCESS,
  }),

  resetSuccessErrorMessage: () => ({
    type: CLIENT_REQUEST_TYPES.RESET_SUCCESS_ERROR_MESSAGE,
  }),

  resetStore: (data: any) => ({
    type: CLIENT_REQUEST_TYPES.RESET_CLIENT_REQUEST_STORE,
  }),

  resetProjectPageData: () => ({
    type: CLIENT_REQUEST_TYPES.RESET_CLIENT_PROJECTS_PAGE_DATA,
  }),

  resetVRListingPageData: () => ({
    type: CLIENT_REQUEST_TYPES.RESET_VENDOR_REQUEST_LIST_PAGE_DATA,
  }),

  resetRFQListingPageData: () => ({
    type: CLIENT_REQUEST_TYPES.RESET_RFQ_LIST_PAGE_DATA,
  }),

  resetVRDetailsPageData: () => ({
    type: CLIENT_REQUEST_TYPES.RESET_VENDOR_REQUEST_DETAILS_PAGE_DATA,
  }),

  resetSelectedProjectDetails: () => ({
    type: CLIENT_REQUEST_TYPES.RESET_SELECTED_PROJECT_DETAILS_DATA,
  }),
  addVRToProject:  (data: any) => ({
    types: [
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_REQUEST,
      CLIENT_REQUEST_TYPES.ADD_VR_TO_PROJECT_SUCCESS,
      CLIENT_REQUEST_TYPES.CLIENT_REQUESTS_ERROR,
    ],
    promise: (client: any) =>
      client.patch("add_vr_to_project", {
        data,
      }),
  }),
  setSelectedClientRequestTab: (selectedTab: number) => ({
    type: CLIENT_REQUEST_TYPES.SET_SELECTED_CLIENT_REQUEST_TAB,
    payload: selectedTab
  }),
  setSelectedClientProjectTab: (selectedTab: number) => ({
    type: CLIENT_REQUEST_TYPES.SET_SELECTED_CLIENT_PROJECT_TAB,
    payload: selectedTab
  })
};

export default ActionCreators;
