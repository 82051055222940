import { JobRequestActions } from "container/JobRequests/actions";
import { QUOTATION_TYPE } from "container/JobRequests/Constants/constants";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Box, ComparedQuotation } from "venwiz-ui";

export const ComparedQuotationPage = () => {
  const { jobId, quoteType } = useParams<{
    jobId: string;
    quoteType: string;
  }>();

  const { compareQuotationRequest } = useSelector(
    (state: any) => state.JobRequestReducers
  );
  const [comparedQuoteData, setComparedQuoteData] = useState<Array<any>>([]);
  const [comparedQuoteFile, setComparedFile] = useState<any>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!compareQuotationRequest) {
      dispatch(JobRequestActions.getCompareQuotation(jobId));
    }
    getTabNames();
  }, []);

  useEffect(() => {
    getTabNames();
  }, [compareQuotationRequest]);

  const getTabNames = () => {
    if (
      quoteType === QUOTATION_TYPE.TECHNICAL &&
      compareQuotationRequest?.comparedTechnicalQuotationsTemplate
    ) {
      setComparedQuoteData(
        compareQuotationRequest?.comparedTechnicalQuotationsTemplate
      );
      setComparedFile(
        compareQuotationRequest?.comparedTechnicalQuotations[
          compareQuotationRequest?.comparedTechnicalQuotations.length - 1
        ]
      );
    } else if (
      quoteType === QUOTATION_TYPE.COMMERCIAL &&
      compareQuotationRequest?.comparedCommercialQuotationsTemplate
    ) {
      setComparedQuoteData(
        compareQuotationRequest?.comparedCommercialQuotationsTemplate
      );
    }
  };
  return (
    !!(comparedQuoteData.length && comparedQuoteFile) && (
      <Box width={"calc(100vw - 73px)"} backgroundColor={"#FFFFFF"}>
        <ComparedQuotation
          comparedQuoteTemplate={comparedQuoteData}
          header={
            jobId +
            " > " +
            (quoteType === QUOTATION_TYPE.TECHNICAL
              ? "Technical Comparison"
              : "Commercial Comparison")
          }
          comparedQuoteFile={comparedQuoteFile}
        />
      </Box>
    )
  );
};
