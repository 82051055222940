import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  InputLabel,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from '@mui/material/Checkbox';
import * as _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dropDownData } from "../../constants/updateVendorConstants";
import ChipsArray from "../ChipsArray/ChipsArray";
import "./VendorCapabilitiesUpdate.scss";
import LocalStorage from "../../_services/LocalStorage";
import NoAccess from "../NoAccess/NoAccess";
import FileStorage from "../FileStorage";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CollectionsIcon from "@mui/icons-material/Collections";
import { countryList, initialState, useStyles } from "./constant";
import { sortSelectedOption } from "./helper";
import { leadSquaredTrigger } from "shared/handlers/leadSquaredTrigger.handler";
import { SelectDropdown } from "components/VendorDocumentsUpdate/SelectDropdown";
import { Close } from "@material-ui/icons";
import AntdToast from "components/Toast/AntdToast";
import { notification } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GalleryModal } from "./components/GalleryModal/GalleryModal";
import { GALLERY_MEDIA_CATEGORIES_OPTIONS } from "./components/GalleryModal/GalleryModal.constants";
import { ManufacturingTable } from "./components/ManufacturingTable/ManufacturingTable";
import {
  IMachine,
  IPlantMachineDetail,
  IMachineHandlerArgType,
  IPlantHandlerArgType,
  ManufCapabilitiesTableProps,
  IAddBulkMachinesHandlerArgType,
  BulkDeleteMachineHandlerArgType
} from "./components/ManufacturingTable/ManufacturingTable.interface";
import {
  FORMIK_MACHINE,
  FORMIK_PLANT,
} from "./components/ManufacturingTable/ManufacturingTable.constants";
import { PLANT_MACHINE_DETAILS } from "./components/ManufacturingTable/ManufacturingTable.mockdata";
import { 
  modules,
  BLOCK_TAG_NAME,
  EMPTY_TEXT_VALUES,
  VISUALLY_EMPTY_STRINGS,
  DIV_WITH_EMPTY_SPACES_PATTERN
} from "components/CustomReactQuillEditor/reactQuill.constants";
import { cleanFormatting } from "components/CustomReactQuillEditor/reactQuill.helper";
import { usePrompt } from "components/Modal/usePrompt";
import { checkUserHasOnlyVendorViewRole } from "shared/helpers/checkHasAccess";

const VendorCapabilitiesUpdate = () => {
  const [loader, enableLoader] = React.useState(false);
  const [showGalleryModal, setShowGalleryModal] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showVideoLoading, setShowVideoLoading] = React.useState(false);
  const [reloadUpdate, setReloadUpdate] = React.useState(false);

  const access = checkUserHasOnlyVendorViewRole()
  const [hasAccess, setHasAccess] = React.useState(access);

  // New Capabilities State
  const [capabilitiesDetails, setCapabilitiesDetails] = React.useState(initialState);
  const [servDesc, setServDesc] = React.useState("");
  const [keyCustomerValue, setKeyCustomerValue] = React.useState("");
  const [productValue, setProductValue] = React.useState("");
  const [plantLocationValue, setPlantLocationValue] = React.useState("");
  const [keyEquipmentValue, setKeyEquipmentValue] = React.useState("");
  const [toolsAndPlantsValue, setToolsAndPlantsValue] = React.useState("");
  const OTHER_CATEGORY = GALLERY_MEDIA_CATEGORIES_OPTIONS?.[2]?.value;
  const [galleryImageCategory, setGalleryImageCategory] =
    React.useState(OTHER_CATEGORY);
  const [galleryVideoCategory, setGalleryVideoCategory] =
    React.useState(OTHER_CATEGORY);
  const [videoUrl, setVideoUrl] = React.useState("");
  const [activeTab, setActiveTab] = React.useState(0); // 0: Image, 1: Video
  const [selectedImgOrVideoObj, setSelectedImgOrVideoObj] = React.useState(
    capabilitiesDetails?.galleryImages?.[0] || {
      file: null,
      videoUrl: "",
      imageName: "",
      caption: "",
      description: "",
      category: OTHER_CATEGORY,
    }
  );

  const Quill = ReactQuill.Quill;
  var Block = Quill.import("blots/block");
  Block.tagName = BLOCK_TAG_NAME;
  Quill.register(Block);

  // MltiSelect fields
  const [natureOfWorkOptions, setNatureOfWorkOptions] = React.useState(
    dropDownData.natureOfWork
  );
  const [industriesServedOptions, setIndustriesServedOptions] = React.useState(
    dropDownData.industries
  );
  const [locationServedOptions, setLocationServedOptions] = React.useState(
    dropDownData.allRegisteredStates
  );
  const [countriesServedOptions, setCountriesServedOptions] = React.useState(
    countryList?.map((ele) => ({ label: ele, value: ele }))
  );
  const [open, setOpen] = useState(false);
  const handleLocationServedPopClose = () => setOpen(false);
  const handleLocationServedPopOpen = () => setOpen(true); 

  const { gstn } = useParams();
  // Checkbox elements
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [oemTagOptions, setOemTagOptions] = useState([]);

  // State related to Manufacturing Table
  /** @type { [IPlantMachineDetail[], any] } */
  const [plantMachineDetails, setPlantMachineDetails] = useState([]);
  /** @type { [IPlantMachineDetail, any] } */
  const [currPlantObj, setCurrPlantObj] = useState(FORMIK_PLANT.INITIAL_STATE);
  /** @type { [IMachine, any] } */
  const [currMachineObj, setCurrMachineObj] = useState(
    FORMIK_MACHINE.INITIAL_STATE
  );
  /** @type { [boolean, any] } */
  const [showAddOrEditPlantModal, setShowAddOrEditPlantModal] = useState(false);
  /** @type { [boolean, any] } */
  const [showAddOrEditMachineModal, setShowAddOrEditMachineModal] = useState(false);
  /** @type { [boolean, any] } */
  const [showLinkGalleryModal, setShowLinkGalleryModal] = useState(false);
  /** index related props for when: 
   * machine/plant is only present in the UI & not yet saved in DB */
  /** @type { [ManufCapabilitiesTableProps["indexOfCurrPlant"], any] } */
  const [indexOfCurrPlant, setIndexOfCurrPlant] = React.useState(null);
  /** @type { [ManufCapabilitiesTableProps["indexOfCurrMachine"], any] } */
  const [indexOfCurrMachine, setIndexOfCurrMachine] = React.useState(null);
  /** @type { [ManufCapabilitiesTableProps["isFormAddNewOrEditExisting"], any] } */
  const [isFormAddNewOrEditExisting, setIsFormAddNewOrEditExisting] = useState(null);
  const [selectAllOption, setSelectAllOption] = useState({ label: "Select All", value: "Select All"});
  const [currDeletedImages, setCurrDeletedImages] = useState([]);

  const prompt = usePrompt();

  const updateVendor = async (gstn, payload) => {
    payload = validateAndPreparePayload(payload);
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/capabilities/new-capabilities/" + gstn,
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result.data.status === "SUCCESS") {
      setReloadUpdate(true);
      updateVendorCategory();
      leadSquaredTrigger(gstn);
        const savedTagsToDb = await axios.get(
          process.env.REACT_APP_API_URL +
            "/v1/tags/saveProfileTextTagsInSuggestedTags/" +
            gstn,
          {
            headers: {
              authorization: `Bearer ${LocalStorage.get("token")}`,
            },
          }
        );
    } else {
      setReloadUpdate(true);
    }
  };

  const updateVendorCategory = () => {
    try {
      const payload = {
        allGstns: false,
        gstns: [gstn]
      }
      axios.post(
        process.env.REACT_APP_API_URL + "/v1/vendor-profile/pull-vendor-category",
        payload
      );
    } catch (err) {
      console.log("Error in updateVendorCategory: "+ err);
    }
  };

  const updateExperience = async (payload) => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "/v1/vendor-profile/delete-media-from-experience",
        payload
      );
      setReloadUpdate(true);
    } catch (err) {
      notification.error({
        top: 70,
        duration: null,
        description: ``,
        message: `Error occured while updating experience gallery`,
      });
      setReloadUpdate(true);
    }
  };

  useEffect(async () => {
    enableLoader(true);
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "/capabilities/new-capabilities/" +
          gstn,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      )
      .then((result) => {
        if (result.data?.status == "SUCCESS") {
          const formattedData = formatCapabilitiesInput(result.data.data);
          setCapabilitiesDetails(formattedData);
          setServDesc(formattedData?.serviceCapabilities?.description || "");
          setPlantMachineDetails(formattedData?.manufacturingCapabilities?.plantMachineDetails || []);
          setMultiSelectOptions(result.data.data);
        }
        enableLoader(false);
        setReloadUpdate(false);
      })
      .catch((error) => {
        // if (error.response.status === 403) {
        setHasAccess(false);
        enableLoader(false);
        // }
      });
  }, [reloadUpdate]);

  const formatCapabilitiesInput = (data) => {
    if (data.capabilitiesCreate) {
      data["manufacturingCapabilities"] =
        initialState.manufacturingCapabilities;
      data["serviceCapabilities"] = initialState.serviceCapabilities;
    }
    if (data.sowCreate) {
      data["keyCustomers"] = initialState.keyCustomers;
      data["aboutUs"] = initialState.aboutUs;
      data["customersListFile"] = initialState.customersListFile;
      data["industryServed"] = initialState.industryServed;
      data["locationServed"] = initialState.locationServed;
      data["industriesServedCountries"] =
        initialState.industriesServedCountries;
    }
    if (data.certificationCreate) {
      data["technical"] = initialState.technical;
    }
    if (data.documentCreate) {
      data["oemDocuments"] = initialState.oemDocuments;
    }
    return data;
  };

  const setMultiSelectOptions = (data) => {
    if (data?.serviceCapabilities?.natureOfWork?.name) {
      const sortedOptions = sortSelectedOption(
        natureOfWorkOptions,
        data?.serviceCapabilities?.natureOfWork?.name
      );
      setNatureOfWorkOptions(sortedOptions);
    }
    if (data?.industryServed?.name) {
      const sortedOptions = sortSelectedOption(
        industriesServedOptions,
        data?.industryServed?.name
      );
      setIndustriesServedOptions(sortedOptions);
    }
    if (data?.industriesServedCountries) {
      const sortedOptions = sortSelectedOption(
        countriesServedOptions,
        data?.industriesServedCountries?.map((ele) => ({
          label: ele,
          value: ele,
        }))
      );
      setCountriesServedOptions(sortedOptions);
    }
  };

  const validateAndPreparePayload = (payload) => {
    if (payload?.technical?.length) {
      payload.technical = payload.technical.filter((element) => element.name);
    }
    if (payload?.oemDocuments?.length) {
      payload.oemDocuments = payload.oemDocuments.filter(
        (element) => element.name
      );
    }
    return payload;
  };

  const openDocument = (chipvalue) => {
    const win = window.open(chipvalue, "_blank");
    win.focus();
  };

  const removeDocuments = (e, element, index, type) => {
    prompt.setIsDataChanged(true);
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    if (
      type === "technical" &&
      capabilitiesDetailsCopy?.technical?.[index]?.certificationFile
    ) {
      delete capabilitiesDetailsCopy.technical[index].certificationFile;
    } else if (type === "oemDocuments") {
      delete capabilitiesDetailsCopy.oemDocuments[index].documentFile;
    }
    setCapabilitiesDetails(capabilitiesDetailsCopy);
  };

  const handleAddField = (e, keyName) => {
    if (e.key === "Enter") {
      let value = e.target.value;
      value = value.replace(/,\s*$/, "");
      let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
      if (keyName === "keyCustomer") {
        const arrayValues = value.split(",");
        arrayValues.forEach((element) => {
          capabilitiesDetailsCopy.keyCustomers.push(element);
        });
        setKeyCustomerValue("");
      } else if (keyName === "manufacturingProducts") {
        const arrayValues = value.split(",");
        arrayValues.forEach((element) => {
          capabilitiesDetailsCopy?.manufacturingCapabilities?.products?.push(
            element
          );
        });
        setProductValue("");
      } else if (keyName === "plantLocation") {
        capabilitiesDetailsCopy?.manufacturingCapabilities?.plantLocation?.push(
          { city: value, capacity: "" }
        );
        setPlantLocationValue("");
      } else if (keyName === "keyEquipments") {
        capabilitiesDetailsCopy?.manufacturingCapabilities?.keyEquipments?.push(
          {
            capacity: "",
            equipment: value,
            make: "",
          }
        );
        setKeyEquipmentValue("");
      } else if (keyName === "toolsAndPlants") {
        capabilitiesDetailsCopy?.serviceCapabilities?.toolsAndPlants?.push(
          value
        );
        setToolsAndPlantsValue("");
      }
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    }
  };

  const removeCustomerChips = (chip) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const currentIndex = capabilitiesDetailsCopy?.keyCustomers?.findIndex(
      (ele) => ele === chip.value
    );
    if (currentIndex !== -1) {
      capabilitiesDetailsCopy.keyCustomers.splice(currentIndex, 1);
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    }
  };
  const removeProductChips = (chip) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const currentIndex =
      capabilitiesDetailsCopy?.manufacturingCapabilities?.products?.findIndex(
        (ele) => ele === chip.value
      );
    if (currentIndex !== -1) {
      capabilitiesDetailsCopy.manufacturingCapabilities?.products?.splice(
        currentIndex,
        1
      );
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    }
  };

  const removePlantLocationChips = (chip) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const currentIndex =
      capabilitiesDetailsCopy?.manufacturingCapabilities?.plantLocation?.findIndex(
        (ele) => ele.city === chip.value
      );
    if (currentIndex !== -1) {
      capabilitiesDetailsCopy.manufacturingCapabilities?.plantLocation?.splice(
        currentIndex,
        1
      );
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    }
  };

  const removeKeyEquipmentChips = (chip) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const currentIndex =
      capabilitiesDetailsCopy?.manufacturingCapabilities?.keyEquipments?.findIndex(
        (ele) => ele.equipment === chip.value
      );
    if (currentIndex !== -1) {
      capabilitiesDetailsCopy.manufacturingCapabilities?.keyEquipments?.splice(
        currentIndex,
        1
      );
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    }
  };

  const removeToolsAndPlantsChips = (chip) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const currentIndex =
      capabilitiesDetailsCopy?.serviceCapabilities?.toolsAndPlants?.findIndex(
        (ele) => ele === chip.value
      );
    if (currentIndex !== -1) {
      capabilitiesDetailsCopy.serviceCapabilities?.toolsAndPlants?.splice(
        currentIndex,
        1
      );
      prompt.setIsDataChanged(true);
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    }
  };

  const uploadFile = async (e, type, index) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/upload",
      data,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result.data.status === "SUCCESS") {
      createPermanentLink(
        result.data.data,
        e.target.files[0].name,
        type,
        index
      );
    } else {
    }
  };

  const createPermanentLink = async (payload, fileName, type, index) => {
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/permanent",
      payload,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result?.data?.status === "SUCCESS") {
      const file = result.data.data;
      let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
      if (type === "technical" && capabilitiesDetailsCopy.technical?.[index]) {
        capabilitiesDetailsCopy.technical[index]["certificationFile"] = file;
      } else if (
        type === "oemDocuments" &&
        capabilitiesDetailsCopy.oemDocuments?.[index]
      ) {
        capabilitiesDetailsCopy.oemDocuments[index]["documentFile"] = file;
      }
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    } else {
    }
  };

  const handleInputChange = (value, type) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    if (type === "aboutUs") {
      capabilitiesDetailsCopy.aboutUs = value;
    } else if (type === "serviceDescription") {
      capabilitiesDetailsCopy.serviceCapabilities.description = value;
    }
    setCapabilitiesDetails(capabilitiesDetailsCopy);
  };

  const handleMultiSelectChange = (e, value, reason, keyName, selected) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    if (keyName === "scopeOfWork") {
      const sortedOption = sortSelectedOption(dropDownData.natureOfWork, value);
      setNatureOfWorkOptions(sortedOption);
      if (capabilitiesDetailsCopy?.serviceCapabilities?.natureOfWork?.name) {
        capabilitiesDetailsCopy.serviceCapabilities.natureOfWork.name = value;
      } else {
        capabilitiesDetailsCopy.serviceCapabilities["natureOfWork"] = {
          name: value,
        };
      }
    } else if (keyName === "industriesServed") {
      const sortedOption = sortSelectedOption(dropDownData.industries, value);
      setIndustriesServedOptions(sortedOption);
      if (capabilitiesDetailsCopy?.industryServed?.name) {
        capabilitiesDetailsCopy.industryServed.name = value;
      } else {
        capabilitiesDetailsCopy["industryServed"] = { name: value };
      }
    } else if (keyName === "locationServed") {
      const ele = value;
      prompt.setIsDataChanged(true);
      let idx = capabilitiesDetailsCopy.locationServed.findIndex(
        (item) => item.country === "India"
      );
      if(idx===-1 && capabilitiesDetailsCopy.locationServed.length!==0){
        idx = capabilitiesDetailsCopy.locationServed.findIndex(
          (item) => !item.country
        );
        if(idx!==-1){
          capabilitiesDetailsCopy.locationServed[idx].country="India"
        }
      }
      if (selected) {
        if (ele.label === "Select All" && ele.value === "Select All") {
          capabilitiesDetailsCopy.locationServed[idx].state = [];
        } else {
          const states = capabilitiesDetailsCopy.locationServed[
            idx
          ].state.filter((item) => item !== ele.value);
          capabilitiesDetailsCopy.locationServed[idx].state = states;
        }
      } else if (capabilitiesDetailsCopy?.locationServed?.[idx]?.state) {
        if (ele.label === "Select All" && ele.value === "Select All") {
          capabilitiesDetailsCopy.locationServed[idx].state =
            locationServedOptions.map((state) => {
              return state.value;
            });
        } else {
          capabilitiesDetailsCopy.locationServed[idx].state = [
            ele.value,
            ...capabilitiesDetailsCopy?.locationServed?.[idx]?.state,
          ];
        }
      } else {
        if (ele.label === "Select All" && ele.value === "Select All") {
          const states = locationServedOptions.map((state) => {
            return state.value;
          });
          capabilitiesDetailsCopy.locationServed = [
            { state: states || [], country: "India" },
            ...capabilitiesDetailsCopy?.locationServed,
          ];
        } else {
          capabilitiesDetailsCopy.locationServed = [
            { state: [ele.value] || [], country: "India" },
            ...capabilitiesDetailsCopy?.locationServed,
          ];
        }
      }
    } else if (keyName === "countriesServed") {
      const sortedOption = sortSelectedOption(
        countryList?.map((ele) => ({ label: ele, value: ele })),
        value
      );
      setCountriesServedOptions(sortedOption);
      if (capabilitiesDetailsCopy?.industriesServedCountries) {
        capabilitiesDetailsCopy.industriesServedCountries =
          value?.map((ele) => ele.value) || [];
      } else {
        capabilitiesDetailsCopy["industriesServedCountries"] =
          value?.map((ele) => ele.value) || [];
      }
    }
    setCapabilitiesDetails(capabilitiesDetailsCopy);
  };

  const handleFileChange = (file, fileName) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const fileObject = {
      name: fileName,
      file: file,
    };
    capabilitiesDetailsCopy["customersListFile"] = fileObject;
    setCapabilitiesDetails(capabilitiesDetailsCopy);
  };

  const removeCustomerFile = (e, element) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    capabilitiesDetailsCopy.customersListFile = null;
    setCapabilitiesDetails(capabilitiesDetailsCopy);
  };

  const handleCatalogFileChange = (file, fileName) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const fileObject = {
      name: fileName,
      file: file,
    };
    if (capabilitiesDetailsCopy.manufacturingCapabilities?.productCatalog) {
      capabilitiesDetailsCopy.manufacturingCapabilities.productCatalog.push(
        fileObject
      );
    } else {
      capabilitiesDetailsCopy.manufacturingCapabilities["productCatalog"] = [
        fileObject,
      ];
    }
    setCapabilitiesDetails(capabilitiesDetailsCopy);
  };

  const removeProductCataolog = (e, element) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const currentIndex =
      capabilitiesDetailsCopy?.manufacturingCapabilities?.productCatalog?.findIndex(
        (entry) => entry.file?.fileUrl === element.file?.fileUrl
      );
    if (currentIndex !== -1) {
      capabilitiesDetailsCopy?.manufacturingCapabilities?.productCatalog?.splice(
        currentIndex,
        1
      );
      prompt.setIsDataChanged(true);
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    }
  };

  const handleTechnicalCertificate = (value, index) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    if (capabilitiesDetailsCopy?.technical?.[index]) {
      capabilitiesDetailsCopy.technical[index].name = value;
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    }
  };

  const handleOemValue = (value, index) => {
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    if (capabilitiesDetailsCopy?.oemDocuments?.[index]) {
      capabilitiesDetailsCopy.oemDocuments[index].name = value;
      setCapabilitiesDetails(capabilitiesDetailsCopy);
    }
  };

  const addNewTechnicalRow = () => {
    prompt.setIsDataChanged(true);
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const newRow = {
      name: "",
    };
    capabilitiesDetailsCopy.technical.push(newRow);
    setCapabilitiesDetails(capabilitiesDetailsCopy);
  };

  const addNewOemRow = () => {
    prompt.setIsDataChanged(true);
    let capabilitiesDetailsCopy = _.cloneDeep(capabilitiesDetails);
    const newRow = {
      name: "",
    };
    capabilitiesDetailsCopy.oemDocuments.push(newRow);
    setCapabilitiesDetails(capabilitiesDetailsCopy);
  };

  const classes = useStyles();

  useEffect(() => {
    setOemTagOptions(
      capabilitiesDetails?.oemTags?.filter(
        (cert) =>
          !capabilitiesDetails?.oemDocuments?.find((itr) => itr.name === cert)
      ) || []
    );
  }, [capabilitiesDetails?.oemDocuments]);


  /**
   * @param { File } file
   * @param { string } fileName
   * @param { any[] } tempGalleryData
   * @param { "image" | "video" } type
   */
  const handleGalleryFileUpload = async (
    file,
    fileName,
    tempGalleryData,
    type,
    currentCategory
  ) => {
    if (type === "image") {
      setShowLoading(true);
    } else {
      setShowVideoLoading(true);
    }
    const data = new FormData();
    data.append("file", file);
    const result = await axios.post(
      process.env.REACT_APP_BASE_URL + "/file-storage/upload",
      data,
      {
        headers: {
          authorization: `Bearer ${LocalStorage.get("token")}`,
        },
      }
    );
    if (result.data.status === "SUCCESS") {
      const permanentFileUploadResult = await axios.post(
        process.env.REACT_APP_BASE_URL + "/file-storage/permanent",
        result.data.data,
        {
          headers: {
            authorization: `Bearer ${LocalStorage.get("token")}`,
          },
        }
      );
      if (permanentFileUploadResult?.data?.status === "SUCCESS") {
        const file = permanentFileUploadResult.data.data;
        //push the file to the image gallery
        if (type === "image") {
          tempGalleryData.push({
            file: file,
            imageName: fileName,
            category: currentCategory,
            caption: fileName.split('.')[0],
          });
        } else {
          tempGalleryData.push({
            file: file,
            name: fileName,
            category: currentCategory,
            caption: fileName.split('.')[0],
          });
        }
        /** set the uploaded image or video object in big-img-or-video-container */
        setSelectedImgOrVideoObj(tempGalleryData[tempGalleryData.length - 1]);
      }
    }
    if (type === "image") {
      setCapabilitiesDetails({
        ...capabilitiesDetails,
        gallery: tempGalleryData,
      });
    } else {
      setCapabilitiesDetails({
        ...capabilitiesDetails,
        companyVideo: tempGalleryData,
      });
    }
    if (type === "image") {
      setShowLoading(false);
    } else {
      setShowVideoLoading(false);
    }
  };

  /**
   * @param {{ e: React.ChangeEvent<HTMLInputElement>, galleryMediaSource: any[], TYPE: "image" | "video", currentCategory: string }} param0
   */
  const handleOnInputChangeForGallery = ({ e, galleryMediaSource, TYPE, currentCategory }) => {
    enableLoader(true);
    const tempGalleryImagesData = _.cloneDeep(galleryMediaSource) || [];
    const tempFile = e?.target?.files;
    for (let i = 0; i < tempFile?.length; i++) {
      const file = tempFile[i];
      const fileName = file?.name;
      handleGalleryFileUpload(file, fileName, tempGalleryImagesData, TYPE, currentCategory);
    }
    enableLoader(false);
  };

  /** handlers for manufacturing capabilities table */
  /** @param {{ currPlant: IPlantMachineDetail, plantIndex?: number }} param0 */
  const handleCurrPlant = ({ currPlant, plantIndex }) => {
    setCurrPlantObj(currPlant);
    setIndexOfCurrPlant(plantIndex);
  };

  /** @param {IMachineHandlerArgType} param0 */
  const handleCurrMachine = ({
    machine: currMachine,
    machineIndex,
    plantIndex: currPlantIndex,
  }) => {
    const currPlant =
      plantMachineDetails?.find((_, idx) => idx === currPlantIndex) || {};
    setCurrPlantObj(currPlant);
    setIndexOfCurrPlant(currPlantIndex);
    setCurrMachineObj(currMachine);
    setIndexOfCurrMachine(machineIndex);
  };  

   /** @param {BulkDeleteMachineHandlerArgType} param0 */
   const handleBulkCurrMachine = ({
    plantIndex: currPlantIndex,
  }) => {
    const currPlant =
      plantMachineDetails?.find((_, idx) => idx === currPlantIndex) || {};
    setCurrPlantObj(currPlant);
    setIndexOfCurrPlant(currPlantIndex);
    setCurrMachineObj({});
    setIndexOfCurrMachine(0);
  };  
  

  const handleCloseManufacturingCapabilitiesModals = () => {
    setCurrPlantObj(FORMIK_PLANT.INITIAL_STATE);
    setCurrMachineObj(FORMIK_MACHINE.INITIAL_STATE);
    setShowAddOrEditPlantModal(false);
    setShowAddOrEditMachineModal(false);
    setShowLinkGalleryModal(false);
    setIndexOfCurrMachine(null);
    setIndexOfCurrPlant(null);
    setIsFormAddNewOrEditExisting(null);
  };

  /** @param {IPlantHandlerArgType} param0 */
  const handleRemovePlant = (args) => {
    const { plantDetail, plantIndex } = args;
    /** @type { typeof plantMachineDetails } */
    let updatedPlantMachineDetails = [];
    /** remove plant */
    for (let i = 0; i < plantMachineDetails?.length; i++) {
      const existingPlant = plantMachineDetails[i];
      if (existingPlant?._id) {
        /** Plant Present in DB */
        if (existingPlant?._id === plantDetail?._id) {
          /** mark existing plant as delete */
          existingPlant["delete"] = true;
          updatedPlantMachineDetails.push(existingPlant);
        } else {
          updatedPlantMachineDetails.push(existingPlant);
        }
      } else {
        /** new plant present only in UI */
        if (i === plantIndex) {
          /** remove existing plant */
          continue;
        } else {
          /** add existing plant to updated list in UI */
          updatedPlantMachineDetails.push(existingPlant);
        }
      }
    }

    setPlantMachineDetails(updatedPlantMachineDetails);
    // toggleIsPageEdited(dispatch, true); // TODO: toggle page edited OR show toast to click save
  };

  /** @param {IMachineHandlerArgType} param0 */
  const handleRemoveMachine = (args) => {
    const { machine, machineIndex, plantIndex } = args;

    /** @type { typeof plantMachineDetails } */
    let updatedPlantMachineDetails = [];
    for (let i = 0; i < plantMachineDetails?.length; i++) {
      const existingPlant = plantMachineDetails[i];
      const isSelectedPlant = i === plantIndex;
      
      /** @type { typeof existingPlant["machines"] } */
      let updatedMachines = [];
      for (let j = 0; j < existingPlant?.machines?.length; j++) {
        const existingMachine = existingPlant?.machines[j];
        if (machine?._id && existingMachine?._id) {
          if (isSelectedPlant && machine?._id === existingMachine?._id) {
            /** mark Machine Present in DB as delete */
            existingMachine["delete"] = true;
            updatedMachines.push(existingMachine);
          } else {
            updatedMachines.push(existingMachine);
          }
        } else {
          if (isSelectedPlant && j === machineIndex) {
            /** exclude new machine present only in UI */
            continue; 
          } else {
            updatedMachines.push(existingMachine); 
          }
        }
      }

      /** update machines in existing plant */
      plantMachineDetails[i]["machines"] = updatedMachines;

      /** add existing plant to updated list in UI */
      updatedPlantMachineDetails.push(existingPlant);
    }

    setPlantMachineDetails(updatedPlantMachineDetails);
    // toggleIsPageEdited(dispatch, true); // TODO: toggle page edited OR show toast to click save
  };

  /** @param {BulkDeleteMachineHandlerArgType} param0 */
  const handleBulkRemoveMachine = (args) => {
    const { plantIndex } = args;

    /** @type { typeof existingPlant["machines"] } */
    let updatedMachines = [];

    /** update machines in  plant */
    plantMachineDetails[plantIndex]["machines"] = updatedMachines;

    setPlantMachineDetails(plantMachineDetails);
  };

  /** @param {IAddBulkMachinesHandlerArgType} param0 */
  const handleAddBulkMachinesToAPlant = (args) => {
    const { 
      plantIndex, 
      plantDetail,
      validMachinesFromCSV,
      invalidMachinesFromCSV,
    } = args;
    /** @type { typeof plantMachineDetails } */
    let updatedPlantMachineDetails = [];
    for (let i = 0; i < plantMachineDetails?.length; i++) {
      const existingPlant = plantMachineDetails[i];
      const isSelectedPlant = i === plantIndex;
      if (isSelectedPlant) {
        /** append new machines to existing plant */
        existingPlant["machines"] = [
          ...existingPlant?.machines,
          ...validMachinesFromCSV,
        ];
      }
      updatedPlantMachineDetails.push(existingPlant);
    }

    setPlantMachineDetails(updatedPlantMachineDetails);
    // toggleIsPageEdited(dispatch, true); // TODO: toggle page edited OR show toast to click save

    if (validMachinesFromCSV?.length) {
      notification.info({
        top: 70, //to display below the nav bar
        duration: null, // non dismissible alert
        description: `Click save to update in Database`,
        message: `${validMachinesFromCSV.length} machines added to the plant`,
      });
    }

    if (invalidMachinesFromCSV?.length) {
      const errorTypes = invalidMachinesFromCSV
        .map((machine) => machine.error)
        .filter((error, index, self) => self.indexOf(error) === index);

      notification.error({
        top: 70, //to display below the nav bar
        duration: null, // non dismissible alert
        description: `Types of Errors:\n${errorTypes.join(", ")}`,
        message: `${invalidMachinesFromCSV?.length} machines from CSV could not be added to the plant`,
      });
    }

    handleCloseManufacturingCapabilitiesModals();

  };

  /**
   * @type { ManufCapabilitiesTableProps["handlers"] }
   */
  const manufCapabilitiesHandlers = {
    plant: {
      handleOnClickAddNewPlant: ({ plantDetail, plantIndex }) => {
        handleCurrPlant({ currPlant: plantDetail });
        setShowAddOrEditPlantModal(true);
        setIsFormAddNewOrEditExisting("ADD_NEW");
      },
      handleOnClickEditPlant: ({ plantDetail, plantIndex }) => {
        handleCurrPlant({ currPlant: plantDetail });
        setShowAddOrEditPlantModal(true);
        setIsFormAddNewOrEditExisting("EDIT_EXISTING");
      },
      handleOnClickDeletePlant: ({ plantDetail, plantIndex }) => {
        prompt.setIsDataChanged(true);
        handleCurrPlant({ currPlant: plantDetail });
        handleRemovePlant({ plantDetail, plantIndex });
      },
    },
    machine: {
      handleOnClickAddNewMachine: ({ machine, machineIndex, plantIndex }) => {
        handleCurrMachine({
          machine: {},
          machineIndex: machineIndex,
          plantIndex: plantIndex,
        });
        setShowAddOrEditMachineModal(true);
        setIsFormAddNewOrEditExisting("ADD_NEW");
      },
      handleOnClickEditMachine: ({ machine, machineIndex, plantIndex }) => {
        handleCurrMachine({ machine, machineIndex, plantIndex });
        setShowAddOrEditMachineModal(true);
        setIsFormAddNewOrEditExisting("EDIT_EXISTING");
      },
      handleOnClickDeleteMachine: ({ machine, machineIndex, plantIndex }) => {
        handleCurrMachine({ machine, machineIndex, plantIndex });
        handleRemoveMachine({ machine, machineIndex, plantIndex });
      },
      handleOnClickBulkDeleteMachine: ({ plantIndex }) => {
        handleBulkCurrMachine({ plantIndex });
        handleBulkRemoveMachine({ plantIndex });
      },
      handleOnClickViewMachineGallery: ({ machine, machineIndex, plantIndex }) => {
        handleCurrMachine({ machine, machineIndex, plantIndex });
        setShowLinkGalleryModal(true);
      },
      handleOnClickAddBulkMachinesToAPlant: ({ plantDetail, plantIndex, validMachinesFromCSV, invalidMachinesFromCSV }) => {
        handleCurrMachine({
          machine: {},
          machineIndex: 0,
          plantIndex: plantIndex,
        });
        handleAddBulkMachinesToAPlant({ plantIndex, validMachinesFromCSV, invalidMachinesFromCSV });
      },
    },
  };

  return hasAccess ? (
    <>
      <div className="container-fluid scrollable-container">
        <div className={classes.peopleInfoContainer}>
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        <div className="row">
          <Box>
            <Box mb={2} display={"flex"} alignItems={"center"}>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                onClick={() => {
                  setShowGalleryModal(true);
                  setActiveTab(0); // show gallery images tab
                  setVideoUrl("");
                  setGalleryImageCategory(OTHER_CATEGORY);
                  setGalleryVideoCategory(OTHER_CATEGORY);
                }}
              >
                <CollectionsIcon style={{ marginRight: "0.5rem" }} />
                View Gallery
              </Button>
              <Box display={"flex"} alignItems={"center"} ml={"20px"}>
                <Box mr={"16px"}>
                  Images - {capabilitiesDetails?.gallery?.length || 0}
                </Box>
                <Box>
                  Videos - {capabilitiesDetails?.companyVideo?.length || 0}
                </Box>
              </Box>
            </Box>
            <InputLabel className={classes.subHeader}>About Us</InputLabel>
          </Box>
          <TextField
            id="outlined-basic"
            multiline
            rows={6}
            value={capabilitiesDetails.aboutUs || ""}
            variant="outlined"
            fullWidth
            onChange={(e) => {
              prompt.setIsDataChanged(true)
              handleInputChange(e.target.value, "aboutUs")
            }}
          />
        </div>
        <div className="row mt-4">
          <InputLabel className={classes.subHeader}>
            Service Description
          </InputLabel>
          {/* Commented for future reference */}
          {/* <TextField
            id="description"
            multiline
            value={capabilitiesDetails?.serviceCapabilities?.description || ""}
            variant="outlined"
            rows={4}
            fullWidth
            onChange={(e) =>
              handleInputChange(e.target.value, "serviceDescription")
            }
          /> */}
          {/* Rich Text Editor */}
          <div className="full-width text-quill-wrapper">
            <ReactQuill
              theme="snow"
              modules={modules}
              preserveWhitespace
              value={servDesc || ""}
              /**
               * FYI: This onChange runs even when the editor is initialized,
               * hence do not update the main capabilitiesDetails state within this,
               * as it will cause the other fields to be reset to empty
               */
              onChange={(value, delta, source, editor) => {
                let formattedValue = cleanFormatting({ stateVal: servDesc || "", content: value });
                if (VISUALLY_EMPTY_STRINGS.includes(formattedValue) || DIV_WITH_EMPTY_SPACES_PATTERN.test(value)) {
                  /** When:
                   * 1. user clears everything in the textarea, visually it looks empty, 
                   *    but the value is not empty, it contains the string value of VISUALLY_EMPTY_STRING
                   * 2. after the above step if the user enters empty spaces, 
                   *    then reset the state value to empty string
                   * 
                   * In the above cases reset the value to empty string & show error,
                   * because service description is a required field
                   */
                  formattedValue = "";
                }
                /**
                 * FYI: this is done to avoid enabling reminder prompt on intialization 
                 */
                if(servDesc!=="" && servDesc!==formattedValue){
                  prompt.setIsDataChanged(true)
                }
                setServDesc(formattedValue);
              }}
            />
          </div>
        </div>
        {/* Image Gallery - Container  */}
        {/* <div className="d-flex flex-column mt-4">
          <InputLabel className={classes.subHeader}>Image Gallery</InputLabel>
          <div>
            <SelectDropdown
              style={{
                width: "200px",
              }}
              label="Select"
              handleOnChange={(e) => setGalleryImageCategory(e.target.value)}
              options={[
                { label: "Product", value: "product" },
                { label: "Factory", value: "factory" },
                { label: "Other", value: "other" },
              ]}
            />
            // Image Upload Button
            <input
              accept="image/*"
              style={{
                display: "none",
              }}
              id="image-gallery-upload"
              multiple
              type="file"
              onChange={(e) => {
                handleOnInputChangeForGallery({
                  e,
                  galleryMediaSource: capabilitiesDetails?.gallery,
                  TYPE: "image"
                });
              }}
            />
            <label htmlFor="image-gallery-upload">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className="ml-3"
                style={{
                  height: "40px",
                }}
              >
                Upload
              </Button>
            </label>
            // Image Chip Container
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
              }}
            >
              <ChipsArray
                ChipsArray={
                  capabilitiesDetails?.gallery?.map((ele) => ({
                    // label: ele.substring(0, 30) + "...",
                    label: ele?.imageName,
                    value: ele?.file?.fileUrl,
                  })) || []
                }
                deletable={true}
                onDelete={(chip) => {
                  const tempVideoGallery = capabilitiesDetails?.gallery?.filter(
                    (ele) => ele.imageName !== chip.label
                  );
                  setCapabilitiesDetails({
                    ...capabilitiesDetails,
                    gallery: tempVideoGallery,
                  });
                }}
                className="full-width key-customer-block"
                onClick={(chip) => {
                  window.open(chip.value, "_blank");
                }}
              ></ChipsArray>
            </div>
          </div>
        </div> */}
        {/* Video Gallery - Container */}
        {/* <div className="d-flex flex-column my-4">
          <InputLabel className={classes.subHeader}>Video Gallery</InputLabel>
          <div className="d-flex align-items-center">
            <SelectDropdown
              style={{
                width: "200px",
              }}
              label="Select"
              handleOnChange={(e) => setGalleryVideoCategory(e.target.value)}
              options={[
                { label: "Product", value: "product" },
                { label: "Factory", value: "factory" },
                { label: "Other", value: "other" },
              ]}
            />
            // Video - Upload Button
            <input
              accept=".mp4"
              style={{
                display: "none",
              }}
              id="video-gallery-upload"
              multiple
              type="file"
              onChange={(e) => {
                handleOnInputChangeForGallery({
                  e,
                  galleryMediaSource: capabilitiesDetails?.companyVideo,
                  TYPE: "video"
                });
              }}
            />
            <label htmlFor="video-gallery-upload">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className="ml-3 mt-2"
                style={{
                  height: "40px",
                }}
              >
                Upload
              </Button>
            </label>

            <Typography
              variant="h6"
              className="mx-4"
              style={{
                color: "rgba(108, 117, 125, 1",
              }}
            >
              Or
            </Typography>
            <TextField
              id="outlined-basic"
              label="Type and Press Add"
              size="small"
              value={videoUrl}
              variant="outlined"
              style={{
                width: "300px",
              }}
              onChange={(e) => {
                setVideoUrl(e.target.value);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              className="ml-3"
              style={{
                height: "40px",
              }}
              onClick={() => {
                if (!videoUrl || videoUrl === "") {
                  AntdToast({
                    text: "Please enter video url",
                    type: "error",
                    description: "Please enter video url",
                  });
                  return;
                }
                //check if url is valid
                const validUrl = videoUrl.match(
                  /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/g
                );
                if (!validUrl) {
                  AntdToast({
                    text: "Please enter valid video url",
                    type: "error",
                    description: "Please enter valid video url",
                  });
                  return;
                }
                const tempVideoGallery =
                  capabilitiesDetails?.companyVideo || [];
                tempVideoGallery.push({
                  videoUrl: videoUrl,
                  name: videoUrl,
                  category: galleryVideoCategory || "other",
                });
                setCapabilitiesDetails({
                  ...capabilitiesDetails,
                  companyVideo: tempVideoGallery,
                });
                setVideoUrl("");
              }}
            >
              Add
            </Button>
          </div>
          // Video URLs - Chip Container
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ChipsArray
              ChipsArray={
                capabilitiesDetails?.companyVideo?.map((ele) => ({
                  // label: ele.substring(0, 30) + "...",
                  label: ele?.name || ele?.videoUrl,
                  value: ele?.file?.fileUrl || ele?.videoUrl,
                })) || []
              }
              deletable={true}
              onDelete={(chip) => {
                const tempVideoGallery =
                  capabilitiesDetails?.companyVideo?.filter(
                    (ele) =>
                      ele?.name !== chip.value &&
                      ele?.file?.fileUrl !== chip.value
                  );
                setCapabilitiesDetails({
                  ...capabilitiesDetails,
                  companyVideo: tempVideoGallery,
                });
              }}
              className="full-width key-customer-block"
              onClick={(chip) => {
                window.open(chip.value, "_blank");
              }}
            ></ChipsArray>
            {showVideoLoading && <CircularProgress color="inherit" />}
          </div>
        </div> */}
        <div className="row mt-4">
          <div className="col">
            <InputLabel id="demo-mutiple-checkbox-label">
              Scope Of Work
            </InputLabel>
            <Autocomplete
              multiple
              limitTags={2}
              id="multi-select-filter"
              options={natureOfWorkOptions}
              value={
                capabilitiesDetails?.serviceCapabilities?.natureOfWork?.name ||
                []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.value}
              renderOption={(props, option) => {
                // let selected = false;
                // selected = capabilitiesDetails?.serviceCapabilities?.natureOfWork?.name?.some(
                //   (ele) => ele.value === option.value
                // );
                return <li {...props}>{option.label}</li>;
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search..." />
              )}
              onChange={(e, value, reason) =>{
                prompt.setIsDataChanged(true)
                handleMultiSelectChange(e, value, reason, "scopeOfWork")
              }}
            />
          </div>
          <div className="col">
            <InputLabel id="demo-mutiple-checkbox-label">
              Industries Served
            </InputLabel>
            <Autocomplete
              multiple
              limitTags={2}
              id="multi-select-filter"
              options={industriesServedOptions}
              value={capabilitiesDetails?.industryServed?.name || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.value}
              renderOption={(props, option) => {
                // let selected = false;
                // selected = capabilitiesDetails?.industryServed?.name?.some((ele) => ele.value === option.value);
                return <li {...props}>{option.label}</li>;
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search..." />
              )}
              onChange={(e, value, reason) =>{
                prompt.setIsDataChanged(true)
                handleMultiSelectChange(e, value, reason, "industriesServed")
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <InputLabel id="demo-mutiple-checkbox-label">
              Location Served(India)
            </InputLabel>
            <Autocomplete
              multiple
              limitTags={2}
              id="multi-select-filter"
              options={[selectAllOption, ...locationServedOptions]}
              open={open}
              onOpen={handleLocationServedPopOpen}
              onClose={handleLocationServedPopClose}
              value={(() => {
                let idx = capabilitiesDetails.locationServed.findIndex(
                  (item) => item.country === "India"
                );
                if(idx===-1 && capabilitiesDetails.locationServed.length!==0){
                  idx = capabilitiesDetails.locationServed.findIndex(
                    (item) => !item.country
                  );
                }
                return (
                  capabilitiesDetails?.locationServed?.[idx]?.state?.map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  ) || []
                );
              })()}
              getOptionLabel={(option) => option.value}
              renderOption={(props, option, { selected }) => {
                let idx = capabilitiesDetails.locationServed.findIndex(
                  (item) => item.country === "India"
                );
                if(idx===-1 && capabilitiesDetails.locationServed.length!==0){
                  idx = capabilitiesDetails.locationServed.findIndex(
                    (item) => !item.country
                  );
                }
                selected =
                  (option.value === "Select All" &&
                    idx != -1 &&
                    capabilitiesDetails?.locationServed?.[idx]?.state.length ===
                      37) ||
                  capabilitiesDetails?.locationServed?.[idx]?.state?.some(
                    (ele) => ele === option.value
                  );
                return (
                  <li
                    {...props}
                    onClick={(e, value, reason) => {
                      handleMultiSelectChange(
                        e,
                        option,
                        reason,
                        "locationServed",
                        selected
                      )
                      handleLocationServedPopClose();
                    }
                    }
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {/* <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} onClick={}/> */}
                    {option.label}
                  </li>
                );
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.value}
                    {...getTagProps({ index })}
                    onDelete={(e) =>
                      handleMultiSelectChange(
                        e,
                        option,
                        "",
                        "locationServed",
                        true
                      )
                    }
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Search..." />
              )}
              clearIcon={
                <ClearIcon
                  fontSize="small"
                  onClick={(e, value, reason) =>{
                    prompt.setIsDataChanged(true)
                    handleMultiSelectChange(
                      e,
                      { label: "Select All", value: "Select All" },
                      reason,
                      "locationServed",
                      true
                    )
                  }}
                />
              }
            />
          </div>
          <div className="col">
            <InputLabel id="demo-mutiple-checkbox-label">
              Location Served(World)
            </InputLabel>
            <Autocomplete
              multiple
              limitTags={2}
              id="multi-select-filter"
              options={countriesServedOptions}
              value={
                capabilitiesDetails?.industriesServedCountries?.map((ele) => ({
                  label: ele,
                  value: ele,
                })) || []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.value}
              renderOption={(props, option) => {
                // let selected = false;
                // selected = capabilitiesDetails?.industriesServedCountries?.some((ele) => ele === option.value);
                return (
                  <li {...props}>
                    {/* <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} /> */}
                    {option.label}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search..." />
              )}
              onChange={(e, value, reason) =>{
                prompt.setIsDataChanged(true)
                handleMultiSelectChange(e, value, reason, "countriesServed")
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <InputLabel className={classes.subHeader}>Key Customers</InputLabel>
            <div className="full-width">
              <ChipsArray
                ChipsArray={
                  capabilitiesDetails?.keyCustomers?.map((ele) => ({
                    // label: ele.substring(0, 30) + "...",
                    label: ele,
                    value: ele,
                  })) || []
                }
                deletable={true}
                onDelete={(chip) => removeCustomerChips(chip)}
                className="full-width key-customer-block"
              ></ChipsArray>
            </div>
            <div className="edit_fields">
              <TextField
                id="outlined-basic"
                label="Type and Press Enter"
                size="small"
                value={keyCustomerValue}
                variant="outlined"
                className="customer_text mt-4"
                style={{ width: "50%" }}
                onChange={(e) => {
                  prompt.setIsDataChanged(true)
                  setKeyCustomerValue(e.target.value)
                }}
                onKeyPress={(e) => {
                  handleAddField(e, "keyCustomer");
                }}
              />
            </div>
          </div>
          <div className="col ml-3">
            <div className="row">
              <InputLabel className={classes.subHeader}>
                Customer File Upload
              </InputLabel>
            </div>
            <FileStorage
              data={
                !_.isEmpty(capabilitiesDetails?.customersListFile)
                  ? [capabilitiesDetails?.customersListFile]
                  : []
              }
              setLocalState={(file, fileName) =>{
                prompt.setIsDataChanged(true)
                handleFileChange(file, fileName)
              }}
              removeDocuments={(e, element) => removeCustomerFile(e, element)}
              acceptableFileTypes={
                "application/pdf,image/jpeg,.xml,.xlsx,.xls,.docx"
              }
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col overflow-adjust">
            <InputLabel className={classes.subHeader}>
              Service (Tools Carried to Site)
            </InputLabel>
            <div className="full-width">
              <ChipsArray
                ChipsArray={
                  capabilitiesDetails?.serviceCapabilities?.toolsAndPlants?.map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  ) || []
                }
                deletable={true}
                onDelete={(chip) => removeToolsAndPlantsChips(chip)}
                className="full-width key-customer-block"
              ></ChipsArray>
            </div>
            <div className="edit_fields">
              <TextField
                id="outlined-basic"
                label="Type and Press Enter"
                size="small"
                value={toolsAndPlantsValue}
                variant="outlined"
                className="customer_text mt-4"
                style={{ width: "50%" }}
                onChange={(e) => {
                  prompt.setIsDataChanged(true)
                  setToolsAndPlantsValue(e.target.value)
                }}
                onKeyPress={(e) => handleAddField(e, "toolsAndPlants")}
              />
            </div>
          </div>
          <div className="col ml-3 overflow-adjust">
            <div className="row">
              <InputLabel className={classes.subHeader}>
                Product Catalog
              </InputLabel>
            </div>
            <FileStorage
              data={
                capabilitiesDetails?.manufacturingCapabilities
                  ?.productCatalog || []
              }
              setLocalState={(file, fileName) =>{
                prompt.setIsDataChanged(true)
                handleCatalogFileChange(file, fileName)
              }}
              removeDocuments={(e, element) =>
                removeProductCataolog(e, element)
              }
              acceptableFileTypes={"application/pdf,image/jpeg,.png"}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col ml-3">
            <div className="row">
              <InputLabel className={classes.subHeader}>
                Technical Certification
              </InputLabel>
            </div>
            <div className="row">
              <div className="col">
                {capabilitiesDetails?.technical?.map((element, index) => (
                  <div className="row ml-1 mb-2 mt-4">
                    <Autocomplete
                      freeSolo
                      disablePortal
                      id="combo-box"
                      options={capabilitiesDetails?.techCerts || []}
                      value={capabilitiesDetails.technical?.[index]?.name || ""}
                      sx={{ width: 200 }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} label="Select..." />
                      )}
                      onChange={(e, value) =>{
                        handleTechnicalCertificate(value, index)
                      }}
                      onInputChange={(e, newInputValue) => {
                        handleTechnicalCertificate(newInputValue, index)
                      }}
                    />
                    <Button
                      variant="contained"
                      component="label"
                      className="upload-button ml-3"
                      startIcon={<FileUploadIcon />}
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/jpeg,image/png,application/pdf"
                        onChange={(e) => {
                          uploadFile(e, "technical", index)
                        }}
                      />
                    </Button>
                    {capabilitiesDetails.technical?.[index]?.certificationFile
                      ?.fileUrl && (
                      <Chip
                        color="default"
                        size="small"
                        className="position-center ml-3"
                        label={
                          element?.certificationFile?.fileName?.length > 23
                            ? element?.certificationFile?.fileName?.substring(0, 20) + "..."
                            : element?.certificationFile?.fileName
                        }
                        onDelete={(e) =>
                          removeDocuments(e, element, index, "technical")
                        }
                        onClick={() =>
                          openDocument(element?.certificationFile?.fileUrl)
                        }
                      />
                    )}
                  </div>
                ))}
                <Button
                  variant="contained"
                  color="add_button"
                  className="ml-2 add_button mb-2"
                  startIcon={<AddIcon />}
                  onClick={() => addNewTechnicalRow()}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col ml-3">
            <div className="row">
              <InputLabel className={classes.subHeader}>OEM</InputLabel>
            </div>
            <div className="row">
              <div className="col">
                {capabilitiesDetails?.oemDocuments?.map((element, index) => (
                  <div className="row ml-1 mb-2 mt-4">
                    <Autocomplete
                      freeSolo
                      disablePortal
                      id="combo-box-demo"
                      options={oemTagOptions || []}
                      value={
                        capabilitiesDetails?.oemDocuments?.[index]?.name || ""
                      }
                      sx={{ width: 200 }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField {...params} label="Select..." />
                      )}
                      onChange={(e, value) => handleOemValue(value, index)}
                      onInputChange={(e, newInputValue) => {
                        handleOemValue(newInputValue, index)
                      }}
                    />
                    <Button
                      variant="contained"
                      component="label"
                      className="upload-button ml-3"
                      startIcon={<FileUploadIcon />}
                    >
                      Upload
                      <input
                        type="file"
                        hidden
                        accept="image/jpeg,image/png,application/pdf"
                        onChange={(e) => {
                          uploadFile(e, "oemDocuments", index)
                        }}
                      />
                    </Button>
                    {capabilitiesDetails.oemDocuments?.[index]?.documentFile
                      ?.fileUrl && (
                      <Chip
                        color="default"
                        size="small"
                        className="position-center ml-3"
                        label={
                          element?.documentFile?.fileName?.length > 23
                            ? element?.documentFile?.fileName?.substring(0, 20) + "..."
                            : element?.documentFile?.fileName
                        }
                        onDelete={(e) =>
                          removeDocuments(e, element, index, "oemDocuments")
                        }
                        onClick={() =>
                          openDocument(element?.documentFile?.fileUrl)
                        }
                      />
                    )}
                  </div>
                ))}
                <Button
                  variant="contained"
                  color="add_button"
                  className="ml-2 add_button mb-2"
                  startIcon={<AddIcon />}
                  onClick={() => addNewOemRow()}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <InputLabel className={classes.subHeader}>
            Manufacturing (Products)
          </InputLabel>
          <ChipsArray
            ChipsArray={
              capabilitiesDetails?.manufacturingCapabilities?.products?.map(
                (ele) => ({ label: ele, value: ele })
              ) || []
            }
            deletable={true}
            onDelete={(chip) => removeProductChips(chip)}
          ></ChipsArray>
          <div className="edit_fields">
            <TextField
              id="outlined-basic"
              label="Type and Press Enter"
              size="small"
              value={productValue}
              variant="outlined"
              className="customer_text mt-4"
              onChange={(e) => setProductValue(e.target.value)}
              onKeyPress={(e) => handleAddField(e, "manufacturingProducts")}
            />
          </div>
        </div>

        {/* <div className="row mt-4">
          <InputLabel className={classes.subHeader}>
            Manufacturing (Plant Location)
          </InputLabel>
          <ChipsArray
            ChipsArray={
              capabilitiesDetails?.manufacturingCapabilities?.plantLocation?.map(
                (ele) => ({
                  label: ele.city,
                  value: ele.city,
                })
              ) || []
            }
            deletable={true}
            onDelete={(chip) => removePlantLocationChips(chip)}
          ></ChipsArray>
          <div className="edit_fields">
            <TextField
              id="outlined-basic"
              label="Type and Press Enter"
              size="small"
              value={plantLocationValue}
              variant="outlined"
              className="customer_text mt-4"
              onChange={(e) => setPlantLocationValue(e.target.value)}
              onKeyPress={(e) => handleAddField(e, "plantLocation")}
            />
          </div>
        </div> */}

        {/* <div className="row mt-4">
          <InputLabel className={classes.subHeader}>
            Manufacturing (Key Equipments)
          </InputLabel>
          <ChipsArray
            ChipsArray={
              capabilitiesDetails?.manufacturingCapabilities?.keyEquipments?.map(
                (ele) => ({
                  label: ele.equipment,
                  value: ele.equipment,
                })
              ) || []
            }
            deletable={true}
            onDelete={(chip) => removeKeyEquipmentChips(chip)}
          ></ChipsArray>
          <div className="edit_fields">
            <TextField
              id="outlined-basic"
              label="Type and Press Enter"
              size="small"
              value={keyEquipmentValue}
              variant="outlined"
              className="customer_text mt-4"
              onChange={(e) => setKeyEquipmentValue(e.target.value)}
              onKeyPress={(e) => handleAddField(e, "keyEquipments")}
              data-temp-attr="deployed"
            />
          </div>
        </div> */}

        <ManufacturingTable
          capabilitiesDetails={capabilitiesDetails}
          setCapabilitiesDetails={setCapabilitiesDetails}
          plantMachineDetails={plantMachineDetails}
          setPlantMachineDetails={setPlantMachineDetails}
          handlers={manufCapabilitiesHandlers}
          setIsDataChanged={prompt.setIsDataChanged}
          // modal related props
          showAddOrEditPlantModal={showAddOrEditPlantModal}
          setShowAddOrEditMachineModal={setShowAddOrEditMachineModal}
          showAddOrEditMachineModal={showAddOrEditMachineModal}
          setShowAddOrEditPlantModal={setShowAddOrEditPlantModal}
          showLinkGalleryModal={showLinkGalleryModal}
          setShowLinkGalleryModal={setShowLinkGalleryModal}
          handleCloseModal={handleCloseManufacturingCapabilitiesModals}
          // curr plant and machine
          currPlantObj={currPlantObj}
          setCurrPlantObj={setCurrPlantObj}
          currMachineObj={currMachineObj}
          setCurrMachineObj={setCurrMachineObj}
          // machine gallery related props
          indexOfCurrPlant={indexOfCurrPlant}
          indexOfCurrMachine={indexOfCurrMachine}
          isFormAddNewOrEditExisting={isFormAddNewOrEditExisting}
          enableLoader={enableLoader}
        />
      </div>

      <div className="row mt-4 save-button-container">
        <div className="col text-right mb-4">
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
            onClick={() => {
              prompt.setIsDataChanged(false);
              /** add latest plantMachineDetails to capabilitiesDetails for POST API CALL */
              capabilitiesDetails.manufacturingCapabilities.plantMachineDetails = plantMachineDetails;
              /** add latest service capabilities to capabilitiesDetails for POST API CALL */
              capabilitiesDetails.serviceCapabilities.description = servDesc || "";
              updateVendor(gstn, capabilitiesDetails);

              /**Clear the experiences gallery as well*/
              const payload = {
                CURR_URLS: currDeletedImages,
                gstn: gstn
              }
              currDeletedImages.length > 0 && updateExperience(payload)
            }}
          >
            Save
          </Button>
        </div>
      </div>
      <GalleryModal
        // plantMachineDetails related states
        plantMachineDetails={plantMachineDetails}
        setPlantMachineDetails={setPlantMachineDetails}
        showVideoLoading={showVideoLoading}
        // capabilities related states
        capabilitiesDetails={capabilitiesDetails}
        setCapabilitiesDetails={setCapabilitiesDetails}
        // gallery related states
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showGalleryModal={showGalleryModal}
        setShowGalleryModal={setShowGalleryModal}
        galleryImageCategory={galleryImageCategory}
        setGalleryImageCategory={setGalleryImageCategory}
        galleryVideoCategory={galleryVideoCategory}
        setGalleryVideoCategory={setGalleryVideoCategory}
        videoUrl={videoUrl}
        setVideoUrl={setVideoUrl}
        selectedImgOrVideoObj={selectedImgOrVideoObj}
        setSelectedImgOrVideoObj={setSelectedImgOrVideoObj}
        // handlers
        handleOnInputChangeForGallery={handleOnInputChangeForGallery}
        setIsDataChanged={prompt.setIsDataChanged}
        setCurrDeletedImages={setCurrDeletedImages}
        currDeletedImages={currDeletedImages}
      />
    </>
  ) : (
    <NoAccess></NoAccess>
  );
};

export default VendorCapabilitiesUpdate;
