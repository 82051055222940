import { Avatar, Box, Button, Chip, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { renderCellExpand } from "../components/CellExpandPopup/CellExpandPopup";
import { SimplePopper } from "components/SimplePopper/SimplePopper";
import { convertFilesArrayToStringsForCSVExport, convertObjToString, getIndividualRegisteredLocationAsString, getStringOfRegisteredLocations, joinArrayOfStrings } from "shared/helpers/csv.helper";
import DoneIcon from "@material-ui/icons/Done";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { openNotification } from "components/Notification/Notification";

export const RETOOL_LINK = "https://venwiz.retool.com/embedded/public/fd699392-0bad-4ab5-bc3b-dc77a7f57e22";

export const APPROVAL_STATUS_DROPDOWN_OPTIONS = [
  { label: "None", value: "" },
  { label: "Approved", value: "approved" },
  { label: "Declined", value: "declined" },
  { label: "Pending", value: "pending" }
];

/** returns column names that do not have value empty */
export const getColumnNamesWithEmptyFields = (log) => {
  let emptyColumnNames = [];

  const pushItemIntoEmptyColumnNames = (itemName) => {
    emptyColumnNames.push(`${emptyColumnNames?.length + 1}. ` + itemName);
  }

  /** For Brochure */
  const isBrochurePresent = convertFilesArrayToStringsForCSVExport(log?.sows?.companyBrochure || []);
  if (isBrochurePresent === "") pushItemIntoEmptyColumnNames("Brochure");

  /** For Website */
  const isWebsitePresent = log?.companyInfo?.website;
  if (!isWebsitePresent) pushItemIntoEmptyColumnNames("Website");

  /** For (Key Customers) */
  const isKeyCustomersPresent = log?.sows?.keyCustomers;
  if (!isKeyCustomersPresent) pushItemIntoEmptyColumnNames("Key Customers");

  /** For Locations Served */
  const isStatesServedPresent = log?.sows?.statesServed?.length > 0;
  if (!isStatesServedPresent) pushItemIntoEmptyColumnNames("Locations Served(states)");

  /** GST Certificate */
  const isGstCertificatePresent = log?.complianceData?.gstCertificate?.file?.fileUrl;
  if (!isGstCertificatePresent) pushItemIntoEmptyColumnNames("GST Certificate");

  /** Project doc1 (Experience 1) */
  const isProjectExperienceDoc1Present = log?.experiences?.experience
    && log?.experiences?.experience?.[0]?.document?.documentFile?.fileUrl;
  if (!isProjectExperienceDoc1Present) pushItemIntoEmptyColumnNames("Project Document upload (Experience 1)");

  /** Total Manpower */
  const totalManpower = log?.peopleStrength?.totalManpower;
  if (totalManpower === "" || !totalManpower) pushItemIntoEmptyColumnNames("Total Manpower");

  /** Key Management */
  const isKeyManagementFirstNamePresent = log?.keyPeopleInfo?.firstName;
  if (!isKeyManagementFirstNamePresent) pushItemIntoEmptyColumnNames("KM First Name");

  /** Technical certificates - Technical (CERTIFICATIONS) */
  const isTechnicalCertificatePresent = log?.certifications?.technical?.length > 0;
  if (!isTechnicalCertificatePresent) pushItemIntoEmptyColumnNames("Technical (CERTIFICATIONS)");

  return emptyColumnNames?.join(" || ");
}

const EXPERIENCE_COUNT = 3;
const REFERENCE_COUNT = 1;
const experienceColumns = [
  { clientCompanyName: "Client Company Name" },
  { description: "Description" },
  { clientIndustry: "Industry" },
  { city: "City" },
  { projStartDate: "Project Start Date" },
  { projEndDate: "Project End Date" },
  { projectFileUpload: "Project Document upload" },
];
const reference = [
  { clientCompanyNameRef: "Client Company Name" },
  { contactNameRef: "Contact Name(POC)" },
  { clientEmailRef: "Client Email" },
  { completionCertificateFileUrlRef: "Reference Document" },
];

export const prepareData = (res) => {
  const resultData = res.map((log, index) => {
    const experienceData = getExperienceColumnsData(log, EXPERIENCE_COUNT);
    const refernceData = getreferenceColumnsData(log, REFERENCE_COUNT);
    return {
      id: log.registrations?._id || index,
      isActive: log.registrations?.isActive,
      vendorPublished: log.registrations?.isActive,
      projectVendor: log.registrations?.projectVendor,
      adminCreated: log.registrations?.adminCreated ? "yes" : "no",
      vendorGst: log.registrations?.gstn,
      approvalStatus: log.registrations?.approvalStatus || "",
      registerDate: log.registrations?.registerDate,
      vendorPublishedTime: log.registrations?.vendorPublishedTime,
      adminUpdatedTime: log.registrations?.adminUpdatedTime,
      vendorUpdatedTime: log.registrations?.vendorUpdatedTime,
      agentCode: log.registrations?.agentCode,
      companyName: log.companyInfo?.companyName,
      address: log.companyInfo?.address,
      city: log.companyInfo?.city,
      state: log.companyInfo?.state,
      pincode: log.companyInfo?.pincode,
      annualTurnOver: log.companyInfo?.annualTurnOver,
      cin: log.companyInfo?.cin,
      turnOverSlab: log.companyInfo?.turnOverSlab,
      yoc: log.companyInfo?.yoc,
      website: log.companyInfo?.website,
      addressProof: log.companyInfo?.addressProof, // array of objects
      gstn: log.registrations?.gstn,
      companyRole: log.registrations?.companyRole,
      email: log.registrations?.email,
      firstName: log.registrations?.firstName,
      lastName: log.registrations?.lastName,
      phoneNumber: log.registrations?.phoneNumber,
      companyRole: log.registrations?.companyRole,
      phoneVerified: log.registrations?.phoneVerified,
      emailVerified: log.registrations?.emailVerified,
      isGoToVendor: log?.registrations?.isGoToVendor ? "true" : "false",
      actualUserUpdatedTime: log?.registrations?.actualUserUpdatedTime || "",
      gstCertificateFileUrl: log.complianceData?.gstCertificate?.file?.fileUrl,
      gstStatus: log.complianceData?.gstStatus,
      pan: log.complianceData?.pan,
      panCertificateFileUrl: log.complianceData?.panCertificate?.file?.fileUrl,
      tan: log.complianceData?.tan,
      lin: log.complianceData?.lin,
      urn: log.complianceData?.urn,
      epf: log.complianceData?.epf,
      registeredEmail: log.financialData?.registeredEmail,
      registeredContact: log.financialData?.registeredContact,
      // cancelledCheque: log.financialData?.cancelledCheque?.fileUrl,
      accountNumber: log.financialData?.accountNumber,
      products: log.manufacturingCapabilties?.products,
      plantLocations: log.manufacturingCapabilties?.plantLocations,
      keyEquipments: log.manufacturingCapabilties?.keyEquipments,
      natureOfWork: log.serviceCapabilities?.natureOfWork,
      disciplines: log.serviceCapabilities?.disciplines,
      description: log.serviceCapabilities?.description,
      kmFirstName: log.keyPeopleInfo?.firstName,
      kmLastName: log.keyPeopleInfo?.lastName,
      kmEmail: log.keyPeopleInfo?.email,
      kmDesignation: log.keyPeopleInfo?.designation,
      kmQualification: log.keyPeopleInfo?.qualification,
      kmExperience: log.keyPeopleInfo?.experience,
      totalManpower: log.peopleStrength?.totalManpower,
      fullTime: log.peopleStrength?.fullTime,
      partTime: log.peopleStrength?.partTime,
      inManufacturing: log.peopleStrength?.inManufacturing,
      inServicing: log.peopleStrength?.inServicing,
      // brochure: log.sows?.fileUrl, // array of strings
      brochure: log?.sows?.companyBrochure || [], // array of objects
      keyCustomers: log.sows?.keyCustomers,
      sowKeyEquipments: log.sows?.keyEquipments,
      aboutUs: log.sows?.aboutUs,
      countriesServed: log.sows?.countriesServed,
      statesServed: log.sows?.statesServed?.join(" | "),
      industriesServed: log.sows?.industriesServed?.join(" | "),
      // industriesServedOther: log.sows?.industriesServedOther,
      certTechnical: log.certifications?.technical,
      certCompliance: log.certifications?.compliance,
      certRegulatory: log.certifications?.regulatory,
      otherDocsName: log.otherDocs?.name,
      otherDocsFile: log.otherDocs?.file,
      customersListFileUrl: log.sows?.customersListFile?.file?.fileUrl,
      equipment: log.tags?.equipment?.join(" | "),
      oem: log.tags?.oem?.join(" | "),
      service: log.tags?.service?.join(" | "),
      equipmentGroups: log.tags?.equipmentGroups?.join(" | "),
      serviceGroups: log.tags?.serviceGroups?.join(" | "),
      doubtfulFlag: log.tags?.flag,
      oemDocs: log.oemDocs?.file,
      // new fields
      caseStudies: log?.companyInfo?.caseStudies,
      ftManufacturing: log?.peopleStrength?.inManufacturing,
      ftService: log?.peopleStrength?.inServicing,
      ftManagement: log?.peopleStrength?.ftManagement,
      ftAdmin: log?.peopleStrength?.ftAdmin,
      ftOther: log?.peopleStrength?.ftOthers,
      ptManufacturing: log?.peopleStrength?.ptManufacturing,
      ptService: log?.peopleStrength?.ptService,
      ptOther: log?.peopleStrength?.ptOther,
      toolsAndPlants: log?.serviceCapabilities?.toolsAndPlants,
      experience: log?.experienceProjectDocs || [],
      productCatalogue: log?.manufacturingCapabilties?.productCatalog || [],
      imageUploads: log?.sows?.gallery || [],
      videoUploads: log?.companyInfo?.companyVideo || [],
      bankName: log?.financialData?.bankName,
      ifscCode: log?.financialData?.ifscCode,
      accountHolderName: log?.financialData?.accountName,
      hsn: log?.companyInfo?.hsn,
      sac: log?.companyInfo?.sac,
      gstVerificationStatus: log?.complianceData?.gstVerification,
      reviews: log?.reviews,
      customerTier: log?.sows?.customerTier || "",
      pendingJobs: log?.jobMonetization?.pending,
      acceptedJobs: log?.jobMonetization?.accepted,
      /** number of secondary users pending approval */
      pendingUserApprovals: log?.secondaryUsers?.pendingUserApprovals || "",
      registeredLocations: log?.companyInfo?.registeredLocations || [],
      missingFields: getColumnNamesWithEmptyFields(log),
      inputGstn: log?.inputGstn, 
      isExactSearchResult: log?.isExactSearchResult,
      ...experienceData,
      ...refernceData
    };
  });
  return resultData;
};

export const formatGstArray = (gst) => {
  if (gst) {
    let gstArray = gst.split(",");
    return gstArray.map((value) => value.trim());
  } else {
    return [];
  }
};

const getExperienceColumnsData = (log, n) => {
  let data = {};
  for (let i = 0; i < n; i++) {
    data["clientCompanyName" + (i + 1)] = log.experiences?.experience[i]?.clientCompanyName;
    data["description" + (i + 1)] = log.experiences?.experience[i]?.description;
    data["clientIndustry" + (i + 1)] = log.experiences?.experience[i]?.clientIndustry;
    data["city" + (i + 1)] = log.experiences?.experience[i]?.city;
    data["projStartDate" + (i + 1)] = log.experiences?.experience[i]?.projStartDate;
    data["projEndDate" + (i + 1)] = log.experiences?.experience[i]?.projEndDate;
    data["projectFileUpload" + (i + 1)] = log.experiences?.experience[i]?.document?.documentFile?.fileUrl;
  }
  return data;
};

const getreferenceColumnsData = (log, n) => {
  let data = {};
  for (let i = 0; i < n; i++) {
    data["clientCompanyNameRef" + (i + 1)] = log["ref" + i]?.clientCompanyName;
    data["contactNameRef" + (i + 1)] = log["ref" + i]?.contactName;
    data["clientEmailRef" + (i + 1)] = log["ref" + i]?.clientEmail;
    data["completionCertificateFileUrlRef" + (i + 1)] = log["ref" + i]?.completionCertificate?.file?.fileUrl;
  }
  return data;
};

const getCommonColumns = (num, columns, type) => {
  let experienceColumnsDef = [];
  for (let i = 0; i < num; i++) {
    columns.forEach((column) => {
      const columnKey = Object.keys(column)[0];
      if (columnKey === "projectFileUpload") {
        experienceColumnsDef.push({
          field: columnKey + (i + 1),
          headerName: column[columnKey] + " (" + type + " " + (i + 1) + ")",
          width: 200,
          align: "center",
          hide: i === 0 ? false : true,
          renderCell: (params) =>
            params.value && params.value != "" ? (
              <a href={params.value} target="_blank">
                <GetAppIcon></GetAppIcon>Download
              </a>
            ) : null,
        });
      } else {
        const columnData = {
          field: columnKey + (i + 1),
          headerName: column[columnKey] + " (" + type + " " + (i + 1) + ")",
          width: 200,
          align: "left",
          renderCell: renderCellExpand,
        };
        if (i === 0 && ["clientCompanyName", "description"].includes(columnKey)) {
          columnData["hide"] = false;
        } else {
          columnData["hide"] = true;
        }
        experienceColumnsDef.push(columnData);
      }
    });
  }
  return experienceColumnsDef;
};

const renderVendorTierCell = (params) => {
  if (!params.value) return <></>;
  return (
    <Box display="flex" gridColumnGap="8px">
      {Object.keys(params.value).map((tierCategory) => {
        if (params?.value?.[tierCategory]?.length > 0) {
          return (
            <SimplePopper
              showContentOnHover
              toggleButton={
                <Chip
                  clickable
                  size="small"
                  color="primary"
                  label={`${tierCategory} - ${params?.value?.[tierCategory]?.length}`}
                />
              }
            >
              <Box display="flex" flexWrap="wrap" maxHeight="20vh" maxWidth="250px" overflow="scroll">
                {params?.value?.[tierCategory].map((tierValues, index) => {
                  return (
                    <Box key={index} paddingTop={1} paddingRight={1}>
                      <Typography variant="overline" display="block" gutterBottom>
                        {` ${tierValues} | `}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </SimplePopper>
          );
        }
      })}
    </Box>
  );
};

const renderFilesPopperContent = (params) => {
  // when no values is present or object is empty
  if (!params?.value || params?.value?.length === 0 || Object.keys(params?.value?.[0]).length === 0) {
    return <></>;
  } else if (params?.value?.length === 1) {
    const fileObj = params?.value[0];
    const FILE_URL = fileObj?.file?.fileUrl || fileObj?.documentFile?.fileUrl || fileObj?.fileUrl || fileObj?.videoUrl;
    const FILE_NAME = fileObj?.name || fileObj?.imageName || fileObj?.file?.fileName || fileObj?.documentFile?.fileName;
    return (
      FILE_URL &&
      renderCellExpand({
        value: (
          <a href={FILE_URL} target="_blank">
            <Chip clickable color="primary" label={FILE_NAME} />
          </a>
        ),
      })
    );
  }

  return (
    <SimplePopper
      toggleButton={
        <>
          Show Files <Chip color="primary" label={params?.value?.length} />
        </>
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        gridRowGap="15"
        maxHeight="20vh"
        overflow={"scroll"}
      >
        {params?.value?.map((fileObj) => {
          const FILE_URL = fileObj?.file?.fileUrl || fileObj?.documentFile?.fileUrl || fileObj?.fileUrl;
          const FILE_NAME =
            fileObj?.name || fileObj?.imageName || fileObj?.file?.fileName || fileObj?.documentFile?.fileName;
          return (
            FILE_URL && (
              <Box p={1}>
                <a key={FILE_URL} href={FILE_URL} target="_blank">
                  <Chip clickable color="primary" label={FILE_NAME} />
                </a>
              </Box>
            )
          );
        })}
      </Box>
    </SimplePopper>
  );
};

/** @param {{value: { gstn: String; fulladdress: { state: string; address: string} }[]}} params */
const renderRegisteredLocationsContent = (params) => {

  const handleCopyAddressToClipBoard = (address) => {
    navigator.clipboard.writeText(address);
    openNotification("address copied to clipboard", "");
  };

  if (!params?.value || params?.value?.length === 0 || Object.keys(params?.value?.[0]).length === 0) {
    // when no values is present or object is empty
    return <></>;
  } else if (params?.value?.length >= 1) {
    return (
      <SimplePopper
        toggleButton={
          <>
            Show Locations
            <Chip
              color="primary"
              style={{ marginLeft: "8px" }}
              label={params?.value?.filter(locationObj => locationObj?.fulladdress?.address)?.length}
            />
          </>
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          gridRowGap="15"
          maxHeight="20vh"
          overflow={"scroll"}
        >
          {params?.value?.map((locationObj) => {
            const ADDRESS_STRING = getIndividualRegisteredLocationAsString(locationObj?.fulladdress);
            if (ADDRESS_STRING) {
              return (
                <Box p={1} key={locationObj} display={"flex"} alignItems={"center"} gridGap={"8px"}>
                  <>
                    <Chip color="primary" style={{ maxWidth: "500px" }} label={ADDRESS_STRING} />
                    <IconButton color="default" onClick={() => handleCopyAddressToClipBoard(ADDRESS_STRING)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </>
                </Box>
              );
            }
          })}
        </Box>
      </SimplePopper>
    );
  }

};

export const columns = [
  {
    field: "registerDate",
    headerName: "Registration Date",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "caseStudies",
    headerName: "Case Studies",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "pendingUserApprovals",
    headerName: "Secondary Users Pending Approval",
    hide: true,
    width: 310,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "productCatalogue",
    headerName: "Product Catalogue",
    hide: true,
    width: 200,
    align: "left",
    /** convert array/objects to strings for DataGrid Export */
    valueFormatter: (params) => convertFilesArrayToStringsForCSVExport(params.value),
    renderCell: (params) => renderFilesPopperContent(params),
  },
  {
    field: "imageUploads",
    headerName: "Image Uploads",
    hide: true,
    width: 200,
    align: "left",
    valueFormatter: (params) => convertFilesArrayToStringsForCSVExport(params.value),
    renderCell: (params) => renderFilesPopperContent(params),
  },
  {
    field: "videoUploads",
    headerName: "Video Uploads",
    hide: true,
    width: 200,
    align: "left",
    valueFormatter: (params) => convertFilesArrayToStringsForCSVExport(params.value),
    renderCell: (params) => renderFilesPopperContent(params),
  },
  {
    field: "experience",
    headerName: "Experience Documents",
    hide: true,
    width: 200,
    align: "left",
    valueFormatter: (params) => convertFilesArrayToStringsForCSVExport(params.value),
    renderCell: (params) => renderFilesPopperContent(params),
  },
  {
    field: "ftManufacturing",
    headerName: "Ft Manufacturing",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "ftService",
    headerName: "FT Service",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "ftManagement",
    headerName: "FT Management",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "ftAdmin",
    headerName: "FT Admin",
    hide: true,
    width: 140,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "ftOther",
    headerName: "FT Other",
    hide: true,
    width: 140,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "ptManufacturing",
    headerName: "PT Manufacturing",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "ptService",
    headerName: "PT Service",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "ptOther",
    headerName: "PT Other",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "toolsAndPlants",
    headerName: "Tools Carried to Site",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "bankName",
    headerName: "Bank Name",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "ifscCode",
    headerName: "IFSC Code",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "accountHolderName",
    headerName: "Account Holder Name",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "hsn",
    headerName: "HSN",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "sac",
    headerName: "SAC",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "gstVerificationStatus",
    headerName: "Gst Verification Status",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "reviews",
    headerName: "Reviews",
    hide: true,
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "adminCreated",
    headerName: "Admin Created",
    width: 180,
    align: "center",
  },
  {
    field: "vendorPublishedTime",
    headerName: "Published Date",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "vendorUpdatedTime",
    headerName: "Vendor Updated Date",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "adminUpdatedTime",
    headerName: "Admin Updated Date",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "companyName",
    headerName: "Company name",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "missingFields",
    headerName: "Missing Fields",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "yoc",
    headerName: "YOC",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "gstn",
    headerName: "GSTIN",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "gstCertificateFileUrl",
    headerName: "GST certificate",
    width: 200,
    align: "center",
    renderCell: (params) =>
      params.value && params.value != "" ? (
        <a href={params.value} target="_blank">
          <GetAppIcon></GetAppIcon>View
        </a>
      ) : null,
  },
  {
    field: "gstStatus",
    headerName: "GST status (Active)",
    width: 200,
    align: "left",
    hide: true,
  },
  {
    field: "pan",
    headerName: "PAN",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "panCertificateFileUrl",
    headerName: "PAN certificate",
    width: 200,
    align: "left",
    hide: true,
    renderCell: (params) =>
      params.value && params.value != "" ? (
        <a href={params.value} target="_blank">
          <GetAppIcon></GetAppIcon>View
        </a>
      ) : null,
  },
  {
    field: "lin",
    headerName: "LIN",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "cin",
    headerName: "CIN",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "urn",
    headerName: "URN",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "esic",
    headerName: "ESIC",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "epf",
    headerName: "EPF",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "tan",
    headerName: "TAN",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "firstName",
    headerName: "First Name",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "isGoToVendor",
    headerName: "Is Go to Vendor",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "actualUserUpdatedTime",
    headerName: "Actual User Updated Time",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "companyRole",
    headerName: "Company Role",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "agentCode",
    headerName: "Agent Code",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "phoneVerified",
    headerName: "Mobile verified",
    width: 170,
    align: "left",
    hide: true,
  },
  {
    field: "emailVerified",
    headerName: "Email verified",
    width: 170,
    align: "left",
    hide: true,
  },
  {
    field: "city",
    headerName: "City",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "state",
    headerName: "State",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "address",
    headerName: "Primary Company Address",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "registeredLocations",
    headerName: "Registered Locations",
    hide: true,
    width: 250,
    align: "left",
    valueFormatter: (params) => getStringOfRegisteredLocations(params.value),
    renderCell: (params) => renderRegisteredLocationsContent(params),
  },
  {
    field: "addressProof",
    headerName: "Address proof",
    width: 200,
    align: "left",
    hide: true,
    valueFormatter: (params) => convertFilesArrayToStringsForCSVExport(params.value),
    renderCell: (params) => renderFilesPopperContent(params),
  },
  {
    field: "website",
    headerName: "Website",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "brochure",
    headerName: "Brochure Link",
    width: 200,
    align: "left",
    valueFormatter: (params) => convertFilesArrayToStringsForCSVExport(params.value),
    renderCell: (params) => renderFilesPopperContent(params),
  },
  {
    field: "turnOverSlab",
    headerName: "Annual Turnover Slab",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "aboutUs",
    headerName: "About Us",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "description",
    headerName: "Service Description",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "natureOfWork",
    headerName: "Scope of Work",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "industriesServed",
    headerName: "Industries Served",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "statesServed",
    headerName: "Location Served(States)",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "countriesServed",
    headerName: "Location Served(world-wide)",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    width: 250,
    align: "left",
    editable: false,
    headerName: "Customer Tier",
    field: "customerTier",
    valueFormatter: (params) => convertObjToString(params?.value),
    renderCell: renderVendorTierCell,
  },
  {
    field: "keyCustomers",
    headerName: "Key Customers",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "customersListFileUrl",
    headerName: "Customer List Upload",
    width: 200,
    align: "center",
    hide: true,
    renderCell: (params) =>
      params.value && params.value != "" ? (
        <a href={params.value} target="_blank">
          <GetAppIcon></GetAppIcon>View
        </a>
      ) : null,
  },
  {
    field: "certTechnical",
    headerName: "Technical (CERTIFICATIONS)",
    width: 200,
    align: "left",
    hide: true,
    /** convert array to strings for DataGrid Export */
    valueFormatter: (params) => joinArrayOfStrings(params.value),
    renderCell: renderCellExpand,
  },
  {
    field: "oemDocs",
    headerName: "OEM Partnership",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "pendingJobs",
    headerName: "Pending pricing consent",
    width: 200,
    align: "left",
    hide: false,
    valueFormatter: (params) => joinArrayOfStrings(params.value),
    renderCell: renderCellExpand,
  },
  {
    field: "acceptedJobs",
    headerName: "Accepted pricing",
    width: 200,
    align: "left",
    hide: false,
    valueFormatter: (params) => joinArrayOfStrings(params.value),
    renderCell: renderCellExpand,
  },
  {
    field: "products",
    headerName: "Manufacturing Products",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "plantLocations",
    headerName: "Plan Location and Capacity",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "keyEquipments",
    headerName: "Key Equipments(Equipments, Make and Capacity)",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  ...getCommonColumns(EXPERIENCE_COUNT, experienceColumns, "Experience"),
  ...getCommonColumns(REFERENCE_COUNT, reference, "Reference"),
  {
    field: "totalManpower",
    headerName: "Total Manpower",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "fullTime",
    headerName: "Manpower Full Time",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "partTime",
    headerName: "Manpower Part Time",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "kmFirstName",
    headerName: "KM First Name",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "kmEmail",
    headerName: "KM Email",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "kmDesignation",
    headerName: "KM Designation",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "kmExperience",
    headerName: "KM Experience(in years)",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "registeredContact",
    headerName: "Financial- Registered contact",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "registeredEmail",
    headerName: "Financial- Registered email",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "certCompliance",
    headerName: "Compliance (CERTIFICATIONS)",
    width: 200,
    align: "left",
    hide: true,
    field: "certifications.compliance",
    valueFormatter: (params) => joinArrayOfStrings(params.value),
    renderCell: renderCellExpand,
  },
  {
    field: "certRegulatory",
    headerName: "Regulatory (CERTIFICATIONS)",
    width: 200,
    align: "left",
    hide: true,
    valueFormatter: (params) => joinArrayOfStrings(params.value),
    renderCell: renderCellExpand,
  },
  {
    field: "otherDocsFile",
    headerName: "Other Documents",
    width: 200,
    align: "left",
    hide: true,
    renderCell: renderCellExpand,
  },
  {
    field: "equipment",
    headerName: "Equipment Tags",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "oem",
    headerName: "OEM Tags",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "service",
    headerName: "Service Tags",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "equipmentGroups",
    headerName: "Equipment Group Tags",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "serviceGroups",
    headerName: "Service Group Tags",
    width: 200,
    align: "left",
    renderCell: renderCellExpand,
  },
  {
    field: "doubtfulFlag",
    headerName: "Doubtful Tag",
    width: 180,
    align: "left",
  },
];

// Columns Backup
// {
//   field: "cancelledCheque",
//   headerName: "Financial- Cheque upload",
//   width: 200,
//   align: "left",
//   hide: true,
//   renderCell: (params) =>
//     params.value && params.value != "" ? (
//       <a href={params.value} target="_blank">
//         <GetAppIcon></GetAppIcon>View
//       </a>
//     ) : null,
// },
// {
//   field: "accountNumber",
//   headerName: "Financial- Account number",
//   width: 200,
//   align: "left",
//   hide: true,
//   renderCell: renderCellExpand,
// },
// {
//   field: "pincode",
//   headerName: "Pincode",
//   width: 200,
//   align: "left",
//   hide: true,
//   renderCell: renderCellExpand
// },
// {
//   field: "turnOverSlab",
//   headerName: "Turn Over Slab",
//   width: 200,
//   align: "left",
//   renderCell: renderCellExpand
// },
// {
//   field: "plantLocations",
//   headerName: "Plan Location and Capacity",
//   width: 200,
//   align: "left",
//   renderCell: renderCellExpand
// },
// {
//   field: "keyEquipments",
//   headerName: "Key Equipments(Equipments, Make and Capacity)",
//   width: 200,
//   align: "left",
//   renderCell: renderCellExpand
// },
// {
//   field: "natureOfWork",
//   headerName: "Nature of Work",
//   width: 200,
//   align: "left",
//   renderCell: renderCellExpand
// },
// {
//   field: "disciplines",
//   headerName: "Disciplines",
//   width: 200,
//   align: "left",
//   renderCell: renderCellExpand
// },
// {
//   field: "inManufacturing",
//   width: 200,
//   headerName: "Manpower in Manufacturing",
//   align: "left",
//   hide: true,
//   renderCell: renderCellExpand
// },
// {
//   field: "inServicing",
//   headerName: "Manpower in Service",
//   width: 200,
//   align: "left",
//   hide: true,
//   renderCell: renderCellExpand
// },
// {
//   field: "sowKeyEquipments",
//   headerName: "Key Equipments",
//   width: 200,
//   align: "left",
//   renderCell: renderCellExpand
// },
// {
//   field: "industriesServedOther",
//   headerName: "Industries Served(others)",
//   width: 200,
//   align: "left",
//   renderCell: renderCellExpand
// },
// {
//   field: "otherDocsName",
//   headerName: "Other Documents Name",
//   width: 200,
//   align: "left",
//   hide: true,
//   renderCell: renderCellExpand
// },
// {
//   field: "otherDocsFile",
//   headerName: "Other Documents",
//   width: 200,
//   align: "left",
//   hide: true,
//   renderCell: renderCellExpand
// },
// {
//   field: "tagFlag",
//   headerName: "Tag Flag",
//   width: 200,
//   align: "center",
//   renderCell: renderCellExpand
// },
