export const TYPES = {
  CLIENT_REQUESTS_REQUEST:  "client_request/CLIENT_REQUESTS_REQUEST",
  CLIENT_REQUESTS_ERROR: "client_request/CLIENT_REQUESTS_ERROR",
  EMAIL_REQUESTED: "email/EMAIL_REQUESTED",
  EMAIL_SUCCESS:  "email/EMAIL_SUCCESS",
  EMAIL_FAILURE:  "email/EMAIL_FAILURE",
  CLIENT_REQUEST_CREATE_PROJECT_SUCCESS: "client_request/CLIENT_REQUEST_CREATE_PROJECT_SUCCESS",

  CLIENT_REQUEST_GET_COMPANIES_SUCCESS: "client_request/CLIENT_REQUEST_GET_COMPANIES_SUCCESS",

  CLIENT_REQUEST_GET_COMPANYS_PLANTS_SUCCESS: "client_request/CLIENT_REQUEST_GET_COMPANYS_PLANTS_SUCCESS",

  CLIENT_REQUEST_GET_PROJECTS_SUCCESS: "client_request/CLIENT_REQUEST_GET_PROJECTS_SUCCESS",
  
  CLIENT_REQUEST_GET_PROJECT_NAMES_SUCCESS: "client_request/CLIENT_REQUEST_GET_PROJECT_NAMES_SUCCESS",

  GET_VENDOR_REQUEST_FOR_COMPANY_SUCCESS: "client_request/GET_VENDOR_REQUEST_FOR_COMPANY_SUCCESS",

  GET_VENDOR_REQUEST_CSV_DATA_FOR_COMPANY_SUCCESS: "client_request/GET_VENDOR_REQUEST_CSV_DATA_FOR_COMPANY_SUCCESS",

  SET_SELECTED_COMPANY: "client_request/SET_SELECTED_COMPANY",
  
  SET_SELECTED_PROJECT: "client_request/SET_SELECTED_PROJECT",

  GET_PROJECT_DETAILS_SUCCESS: "client_request/GET_PROJECT_DETAILS_SUCCESS",

  GET_USERS_LIST_SUCCESS: "client_request/GET_USERS_LIST_SUCCESS",
  
  ADD_OR_REMOVE_USER_FROM_ROLE_SUCCESS: "client_request/ADD_OR_REMOVE_USER_FROM_ROLE_SUCCESS",

  GET_RFQ_FOR_PROJECT_SUCCESS: "client_request/GET_RFQ_FOR_PROJECT_SUCCESS",
  
//***  Job Requests Types ***//
  GET_JOB_REQUEST_DETAILS_SUCCESS: "client_request/GET_JOB_REQUEST_DETAILS_SUCCESS",
 
  CREATE_VENDOR_REQUEST_SUCCESS: "client_request/CREATE_VENDOR_REQUEST_SUCCESS",
  
  UPDATE_JOB_REQUEST_SUCCESS: "client_request/UPDATE_JOB_REQUEST_SUCCESS",

  SET_JOB_REQUEST_DATA: "client_request/SET_JOB_REQUEST_DATA",

  UPDATE_JOB_STATUS_SUCCESS: "client_request/UPDATE_JOB_STATUS_SUCCESS",
 
  ADD_RFQ_DOCS_SUCCESS: "client_request/ADD_RFQ_DOCS_SUCCESS",
  
  REMOVE_RFQ_DOCS_SUCCESS: "client_request/REMOVE_RFQ_DOCS_SUCCESS",

  GET_PQR_TEMPLATES_SUCCESS: "client_request/GET_PQR_TEMPLATES_SUCCESS",

  GENERATE_AUTO_PQR_SUCCESS: "client_request/GENERATE_AUTO_PQR_SUCCESS",
//---------------------------//

  ADD_VR_TO_PROJECT_SUCCESS:"client_request/ADD_VR_TO_PROJECT_SUCCESS",

//** Job Monetization Types **//
  UPDATE_VENDOR_MONETIZATION_REQUESTED: "client_request/UPDATE_VENDOR_MONETIZATION_REQUESTED",
  UPDATE_VENDOR_MONETIZATION_SUCCESS: "client_request/UPDATE_VENDOR_MONETIZATION_SUCCESS",
  UPDATE_VENDOR_MONETIZATION_FAILURE: "client_request/UPDATE_VENDOR_MONETIZATION_FAILURE", 

  UPDATE_VENDOR_MONETIZATION_VALUE: "client_request/UPDATE_VENDOR_MONETIZATION_VALUE",

  SEND_AGREEMENT_TO_VENDOR_SUCCESS: "client_request/SEND_AGREEMENT_TO_VENDOR_SUCCESS",

  GENERATE_PRICING_AGREEMENT_SUCCESS: "client_request/GENERATE_PRICING_AGREEMENT_SUCCESS",
  
//------------------------//
  //RESET
  RESET_CREATE_PROJECT_SUCCESS: "client_request/RESET_CREATE_PROJECT_SUCCESS",

  RESET_SUCCESS_ERROR_MESSAGE: "client_request/RESET_SUCCESS_ERROR_MESSAGE",

  RESET_CLIENT_REQUEST_STORE: "client_request/RESET_CLIENT_REQUEST_STORE",
  
  RESET_VENDOR_REQUEST_LIST_PAGE_DATA: "client_request/RESET_VENDOR_REQUEST_LIST_PAGE_DATA",

  RESET_CLIENT_PROJECTS_PAGE_DATA: "client_request/RESET_CLIENT_PROJECTS_PAGE_DATA",

  RESET_VENDOR_REQUEST_DETAILS_PAGE_DATA: "client_request/RESET_VENDOR_REQUEST_DETAILS_PAGE_DATA",

  RESET_SELECTED_PROJECT_DETAILS_DATA: "client_request/RESET_SELECTED_PROJECT_DETAILS_DATA",

  RESET_RFQ_LIST_PAGE_DATA: "client_request/RESET_RFQ_LIST_PAGE_DATA",

  SET_SELECTED_CLIENT_REQUEST_TAB: "client_request/SET_SELECTED_CLIENT_REQUEST_TAB",

  SET_SELECTED_CLIENT_PROJECT_TAB: "client_request/SET_SELECTED_CLIENT_PROJECT_TAB"
  
}